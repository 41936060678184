import axios from 'axios';

 export const LIVE = 'https://27samaj.com/manager';
export const API_KEY = 'com.pateltechnolab.samajapp';
export async function PostAPI(url) {
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const promise = axios({
    method: 'post',
    baseURL: LIVE,
    url: url + API_KEY,
    headers: headers,
  });
  
  const dataPromise = promise.then(response => response.data);
  return dataPromise;
}

export async function PostRequestAPI(url,data) {
  const headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    const promise = axios({
      method: 'post',
      baseURL: LIVE,
      url: url + API_KEY,
      data:data,
      headers: headers
    })
  const dataPromise = promise.then((response) => response.data)
  return dataPromise
}

export async function PostRequestFileAPI(url,formData)
{
      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      const promise = axios({
        method: 'post',
        baseURL: LIVE,
        url: url + API_KEY,
        data: formData,
        headers: headers,
      })
      const dataPromise = promise.then((response) => response.data)
      return dataPromise
}