import { configureStore } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import apiReducer from './apiSlice';
import reducerSlice from './reducerSlice';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducerSlice);

const store = configureStore({
  reducer: {
    country: persistedReducer,
    api: apiReducer,
  },
});

export const persistor = persistStore(store);

export { store }; // Exporting the store separately
export default store; // Exporting the store as default
