import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import parse from 'html-react-parser';
import "../styles/NewsPage.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Skeleton, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../styles/NewsPage.scss";
import { Imageurl } from "../Features/apisUrl";

const NewsPage = () => {
  const location = useLocation();
  const content = location?.state?.data || {};
  const [news, setNews] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (content) {
      setNews(content);
    }
  }, [content]);

  const handleGoBack = () => {
    navigate(-1);
  };

  if (!news) {
    return (
      <div>
        <Header />
        <div className="news-page">
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={20} width="80%" />
          <Skeleton animation="wave" height={20} width="70%" />
          <Skeleton animation="wave" height={20} width="90%" />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="news-page">
        <div className="news-header">
          <IconButton onClick={() => navigate(-1)} color="primary">
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h5" className="news-title">
            {news.news_title}
          </Typography>
        </div>
        <div className="news-content">
          <div className="news-scroll-container">
            <img className="news-image" src={Imageurl.news_image + news.news_image} alt={news.news_title} />
            <div className="news-desc">{parse(news.news_desc)}</div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
