import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PhotoGalleryAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Photogallery() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const response = await PhotoGalleryAPI({ offset });
        if (response) {
          setData(response);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching photo gallery data:", error);
      }
    })();
  }, [offset]);

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedImage(null);
  };

  return (
    <>
      <Box mb={6}>
        <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-around', overflow: 'hidden', backgroundColor: 'inherit' }}>
          <Grid container spacing={3}>
            {data.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={index} style={{ cursor: 'pointer' }}>
                <Card onClick={() => handleImageClick(`${Imageurl.photo_path + item.photo_gallery_link}`)}>
                  <CardMedia
                    component="img"
                    image={`${Imageurl.photo_path + item.photo_gallery_link}`}
                    title={item.title}
                    style={{ height: 200, transition: 'transform 0.3s ease-in-out', '&:hover': { transform: 'scale(1.1)' } }}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </Box>

      <Dialog fullScreen open={openModal} onClose={handleCloseModal} TransitionComponent={Transition}>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton edge="end" color="inherit" onClick={handleCloseModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center">
            {selectedImage && (
              <img src={selectedImage} alt="" style={{ maxWidth: "100%", maxHeight: "80vh", objectFit: "contain" }} />
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Photogallery;
