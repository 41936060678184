import { useState, useEffect } from "react";
import { Card, Grid, InputLabel, TextField, Select, MenuItem, Box, Button, Typography, IconButton } from "@mui/material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useLocation, useNavigate } from "react-router-dom";
import { view_donation_categoryAPI } from "../Features/services";
import { Strings } from "../Common/String";
import "../styles/MakeDonation.scss";
import { format } from 'date-fns';

function MakeDonation() {
  const history = useNavigate();
  const [donation_categorydata, setdonation_categorydata] = useState([]);
  const [donation_category_id, setdonation_category_id] = useState("");
  const [date, setdate] = useState("");
  const [today, setToday] = useState("");
  const [amount, setamount] = useState("");
  const [validationText, setValidationText] = useState("");
  const [fieldErrors, setFieldErrors] = useState({
    date: "",
    amount: "",
    donation_category: ""
  });

  useEffect(() => {
    const todayDate = new Date();
    const formattedToday = format(todayDate, 'yyyy-MM-dd');
    setToday(formattedToday);
    setdate(formattedToday);
  }, []);


  const setValidation = () => {
    let isValid = true;
    let errors = { date: "", amount: "", donation_category: "" };

    if (date === "") {
      errors.date = Strings.PleaseEnterDate;
      isValid = false;
    }
    if (amount === "") {
      errors.amount = Strings.PleaseEnteryourAmount;
      isValid = false;
    }
    if (donation_category_id === "") {
      errors.donation_category = Strings.PleaseSelectDonationCategory;
      isValid = false;
    }

    setFieldErrors(errors);
    setValidationText(Object.values(errors).filter(error => error !== "").join(" "));
    return isValid;
  };

  useEffect(() => {
    (async () => {
      const donation_categoryResponse = await view_donation_categoryAPI({});
      if (donation_categoryResponse) {
        setdonation_categorydata(
          donation_categoryResponse
            .sort((a, b) => a.category_name.localeCompare(b.category_name))
            .map((item) => ({
              label: item.category_name,
              value: item.category_id,
            }))
        );
      }
    })();
  }, []);

  const handleChange = (event) => {
    setdonation_category_id(event.target.value);
    if (event.target.value) {
      setFieldErrors(prev => ({ ...prev, donation_category: "" }));
    }
  };

  const handleDateChange = (event) => {
    setdate(event.target.value);
    if (event.target.value) {
      setFieldErrors(prev => ({ ...prev, date: "" }));
    }
  };

  const handleAmountChange = (event) => {
    setamount(event.target.value);
    if (event.target.value) {
      setFieldErrors(prev => ({ ...prev, amount: "" }));
    }
  };

  return (
    <Box mt={5} mb={3} className="make-donation-container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box display="flex" alignItems="center" mb={2}>
            <IconButton onClick={() => history(-1)}>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h6" ml={2}>
              {Strings.MakeaDonation}
            </Typography>
          </Box>
          <Card className="make-donation-card">
            <Box pt={2} pb={3} px={3}>
              <form role="form">
                <Box mb={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <InputLabel className="input-label">
                        {Strings.date}
                      </InputLabel>
                      <TextField
                        type="date"
                        fullWidth
                        onChange={handleDateChange}
                        value={date}
                        inputProps={{ min: today }}
                        error={Boolean(fieldErrors.date)}
                        helperText={fieldErrors.date}
                        InputProps={{
                          style: {
                            color: '#888' ,
                          },
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel className="input-label">
                        {Strings.amount}
                      </InputLabel>
                      <TextField
                        type="number"
                        fullWidth
                        placeholder={Strings.amount}
                        onChange={handleAmountChange}
                        value={amount}
                        error={Boolean(fieldErrors.amount)}
                        helperText={fieldErrors.amount}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel className="input-label">
                        {Strings.Donation_category}
                      </InputLabel>
                      <Select
                        fullWidth
                        value={donation_category_id}
                        onChange={handleChange}
                        displayEmpty
                        error={Boolean(fieldErrors.donation_category)}
                        style={{
                          color: '#AEAFAF',
                          '& .MuiInput-underline:before': {
                            borderBottomColor: '#AEAFAF',
                          },
                          '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                            borderBottomColor: '#AEAFAF',
                          },
                        }}
                        inputProps={{ "aria-label": "Without label" }}
                      >
                        <MenuItem value="" disabled>
                          {Strings.Select_Donation_category}
                        </MenuItem>
                        {donation_categorydata.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {fieldErrors.donation_category && (
                        <Typography variant="caption" color="error">
                          {fieldErrors.donation_category}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <InputLabel className="input-label">
                        {Strings.name}
                      </InputLabel>
                      <TextField
                        fullWidth
                        placeholder={Strings.name}
                        disabled
                        value={
                          `${localStorage.getItem("surname")} ${localStorage.getItem("first_name")} ${localStorage.getItem("last_name")} ${localStorage.getItem("middle_name")}`
                        }
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box mt={4} mb={1} textAlign="right">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (setValidation()) {
                        // Add the submission logic here
                      }
                    }}
                  >
                    {Strings.pay_online}
                  </Button>
                </Box>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MakeDonation;
