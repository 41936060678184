import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import WelcomePage from "./components/WelcomePage";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Otp from "./components/Otp";
import Dashboard from "./components/Dashboard";
import History from "./components/History";
import Besnu from "./components/Besnu";
import MainLayout from "./components/ParentSidebarRouting";
import "./App.css";
import News from "./components/News";
import Donations from "./components/Donations";
import Native from "./components/Native";
import Business from "./components/Business";
import Mybusiness from "./components/Mybusiness";
import Managebusiness from "./components/Managebusiness";
import Marriage from "./components/Marriage";
import Blooddonors from "./components/Blooddonors";
import Memberregistration from "./components/Memberregistration";
import Search from "./components/Search";
import Familymembers from "./components/Familymembers";
import Appdonors from "./components/Appdonors";
import Committeelist from "./components/Committeelist";
import Profile from "./components/Profile";
import Photogallery from "./components/Photogallery";
import Videogallery from "./components/Videogallery";
import Query from "./components/Query";
import Contactus from "./components/Contactus";
import NewsPage from "./components/NewsPage";
import BesnuData from "./components/BesnuData";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsConditions from "./components/TermsConditions";
import BesnuDetail from "./components/BesnuDetail";
import BusinessDetail from "./components/BusinessDetail";
import MyBusinessDetail from "./components/MyBusinessDetail";
import MemberDetail from "./components/MemberDetail";
import BloodDonorList from "./components/BloodDonorList";
import SearchMemberView from "./components/SearchMemberView";
import CommitteeMemberList from "./components/CommitteeMemberList";
import AppDonorsList from "./components/AppDonorsList";
import AboutUs from "./components/AboutUs";
import JmDeathList from "./components/JmDeathList";
import PaidHistory from "./components/PaidHistory";
import JMMembers from "./components/JMMembers";
import DonerList from "./components/DonerList";
import MakeDonation from "./components/MakeDonation";
import DonationList from "./components/DonationList";
import FamilyHeadList from "./components/FamilyHeadList";
import FamilyDetails from "./components/FamilyDetails";
import NewsScreenDetail from "./components/NewsScreenDetail";

const PrivateRoute = ({ element }) => {
  const checkContact = localStorage.getItem("contact");
  return checkContact ? element : <Navigate to="/" />;
};


function App() {
  return (
    <Router>
      <div className="App">
        <main>
          <Routes>
            <Route path="/" element={<WelcomePage />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/otp" element={<Otp />} />
            <Route path="*" element={<WelcomePage />} />
            <Route path="/news" element={<NewsPage />} />
            <Route path="/besnudata" element={<BesnuData />} />
            <Route path="/privacypolicy" element={<PrivacyPolicy />} />
            <Route path="/termsconditions" element={<TermsConditions />} />
            <Route
              path="/dashboard"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Dashboard />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/history"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <History />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/besnu"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Besnu />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/besnudetails"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <BesnuDetail />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/newsscreen"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <News />
                    </MainLayout>
                  }
                />
              }
            />
              <Route
              path="/newsscreendetail"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <NewsScreenDetail />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/aboutus"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <AboutUs />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/jmdeathlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <JmDeathList />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/paidhistory"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <PaidHistory />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/jmmembers"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <JMMembers />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/donations"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Donations />
                    </MainLayout>
                  }
                />
              }
            />
              <Route
              path="/donerlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <DonerList />
                    </MainLayout>
                  }
                />
              }
            />
                  <Route
              path="/makedonation"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <MakeDonation />
                    </MainLayout>
                  }
                />
              }
            />
                  <Route
              path="/donationlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <DonationList />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/native"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Native />
                    </MainLayout>
                  }
                />
              }
            />
               <Route
              path="/familyheadlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <FamilyHeadList />
                    </MainLayout>
                  }
                />
              }
            />
                 <Route
              path="/familydetails"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <FamilyDetails />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/business"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Business />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/businessdetail"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <BusinessDetail />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/mybusiness"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Mybusiness />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/mybusinessdetail"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <MyBusinessDetail />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/managebusiness"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Managebusiness />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/marriage"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Marriage />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/memberdetail"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <MemberDetail />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/blooddonors"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Blooddonors />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/blooddonorlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <BloodDonorList />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/memberregistration"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Memberregistration />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/search"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Search />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/viewallmember"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <SearchMemberView />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/familymembers"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Familymembers />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/committeelist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Committeelist />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/committeememberlist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <CommitteeMemberList />
                    </MainLayout>
                  }
                />
              }
            />
            {/* <Route
              path="/appdonors"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Appdonors />
                    </MainLayout>
                  }
                />
              }
            /> */}
            {/* <Route
              path="/appdonorslist"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <AppDonorsList />
                    </MainLayout>
                  }
                />
              }
            /> */}
            <Route
              path="/profile"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Profile />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/photogallery"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Photogallery />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/videogallery"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Videogallery />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/query"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Query />
                    </MainLayout>
                  }
                />
              }
            />
            <Route
              path="/contactus"
              element={
                <PrivateRoute
                  element={
                    <MainLayout>
                      <Contactus />
                    </MainLayout>
                  }
                />
              }
            />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;
