import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { Strings } from "../Common/String";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Add_business_UpdateAPI,
  Add_business_detailsAPI,
  view_business_categoryAPI,
  UpdateImage2,
} from "../Features/services";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

export default function Managebusiness() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit] = useState(
    location && location.state && location.state?.data ? true : false
  );
  const reduxData = useSelector((state) => state.country);
  const [citydata, setcitydata] = useState([]);
  const [city_id, setcity_id] = useState(
    isEdit ? location.state?.data.city_id : 0
  );
  const [countrydata, setcountrydata] = useState([]);
  const [country_id, setcountry_id] = useState(
    isEdit ? location.state?.data.country_id : 0
  );
  const [BusinessCategorydata, setBusinessCategorydata] = useState([]);
  const [category_id, setcategory_id] = useState(
    isEdit ? location.state?.data.category_id : 0
  );
  const [address, setaddress] = useState(
    isEdit ? location.state?.data.address : ""
  );
  const [pin, setpin] = useState(isEdit ? location.state?.data.pin : "");
  const [business_name, setbusiness_name] = useState(
    isEdit ? location.state?.data.business_name : ""
  );
  const [concern_person, setconcern_person] = useState(
    isEdit ? location.state?.data.concern_person : ""
  );
  const [description, setdescription] = useState(
    isEdit ? location.state?.data.description : ""
  );
  const [contact_1, setcontact_1] = useState(
    isEdit ? location.state?.data.contact_1 : ""
  );
  const [contact_2, setcontact_2] = useState(
    isEdit ? location.state?.data.contact_2 : ""
  );
  const [email, setemail] = useState(isEdit ? location.state?.data.email : "");
  const [website, setwebsite] = useState(
    isEdit ? location.state?.data.website : ""
  );
  const [location1, setlocation1] = useState(
    isEdit ? location.state?.data.location : ""
  );
  const [business_youtube, setbusiness_youtube] = useState(
    isEdit ? location.state?.data.business_youtube : ""
  );
  const [business_telegram, setbusiness_telegram] = useState(
    isEdit ? location.state?.data.business_telegram : ""
  );
  const [business_facebook, setbusiness_facebook] = useState(
    isEdit ? location.state?.data.business_facebook : ""
  );
  const [business_insta, setbusiness_insta] = useState(
    isEdit ? location.state?.data.business_insta : ""
  );
  const [business_linkedin, setbusiness_linkedin] = useState(
    isEdit ? location.state?.data.business_linkedin : ""
  );
  const [business_catalogue, setbusiness_catalogue] = useState(
    isEdit ? location.state?.data.business_catalogue : ""
  );
  const [business_catalogue_old, setbusiness_catalogue_old] = useState("");
  const [family_member_id, setfamily_member_id] = useState("");
  const [education_id, seteducation_id] = useState(1);
  const [image_1_old, setimage_1_old] = useState(
    isEdit ? location.state?.data.image_1 : ""
  );
  const [image_1, setimage_1] = useState(
    isEdit ? location.state?.data.image_1 : ""
  );
  const [bussImage, setBussImage] = useState(
    isEdit ? location.state?.data.image_1 : ""
  );
  const [dataImage, setDataImage] = useState(isEdit ? location.state?.data.image_1 : "");
  const [dataDocs, setDataDocs] = useState(isEdit ? location.state?.data.business_catalogue : "");
  const [errors, setErrors] = useState({});

  console.log('location.state?.data ', location.state?.data)

  // useEffect(() => {
  //   (async () => {
  //     const bloodResponce = await CurrentCountry_API();
  //     if (bloodResponce) {
  //       setcountrydata(
  //         bloodResponce?.data
  //           .sort((a, b) => a.country_name.localeCompare(b.country_name))
  //           .map((item) => {
  //             return {
  //               label: item.country_name,
  //               value: item.country_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.countryData) {
      const sortedCountries = reduxData.countryData
        .slice()
        .sort((a, b) => a.country_name.localeCompare(b.country_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.country_name,
        value: item.country_id,
      }));
      setcountrydata(mappedCountries);
    }
  }, []);

  //city
  // useEffect(() => {
  //   (async () => {
  //     const sitePartyResponce = await City_API();
  //     if (sitePartyResponce) {
  //       setcitydata(
  //         sitePartyResponce?.data
  //           .sort((a, b) => a.city_name.localeCompare(b.city_name))
  //           .map((item) => {
  //             return {
  //               label: item.city_name,
  //               value: item.city_id,
  //             };
  //           })
  //       );
  //     }
  //   })();
  // }, []);

  useEffect(() => {
    if (reduxData && reduxData.cityData) {
      const sortedCountries = reduxData.cityData
        .slice()
        .sort((a, b) => a.city_name.localeCompare(b.city_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.city_name,
        value: item.city_id,
      }));
      setcitydata(mappedCountries);
    }
  }, []);

  // view_business_categoryAPI
  useEffect(() => {
    (async () => {
      const CategoryResponce = await view_business_categoryAPI({
        education_id: education_id,
      });
      if (CategoryResponce) {
        setBusinessCategorydata(
          CategoryResponce?.data
            .sort((a, b) => a.category_name.localeCompare(b.category_name))
            .map((item) => {
              return {
                label: item.category_name,
                value: item.category_id,
              };
            })
        );
      }
    })();
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setbusiness_catalogue(file ? file.name : "");
    setDataDocs(file);
  };

  const handlebusiness = (e) => {
    const file = e.target.files[0];
    setBussImage(file ? file.name : "");
    setDataImage(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = {};

    if (business_name == "") {
      newErrors.business_name = Strings.Pleaseenterabusinessname;
    }
    if (concern_person == "") {
      newErrors.concern_person = Strings.Pleaseenteraconcernperson;
    }
    if (description == "") {
      newErrors.description = Strings.Pleaseenteradescription;
    }
    if (category_id == "") {
      newErrors.category_id = Strings.Pleaseenteracategory;
    }
    if (address == "") {
      newErrors.address = Strings.Pleaseenteraaddress;
    }
    if (pin == "") {
      newErrors.pin = Strings.Pleaseenterapin;
    }
    if (city_id == "") {
      newErrors.city_id = Strings.Pleaseenteracity;
    }
    if (country_id == "") {
      newErrors.country_id = Strings.Pleasecountry;
    }
    if (contact_1 == "") {
      newErrors.contact_1 = Strings.Pleaseenteracontact;
    }
    // if (contact_2 == "") {
    //   newErrors.contact_2 = Strings.Pleaseenteracontact;
    // }
    // if (email == "") {
    //   newErrors.email = Strings.Pleaseemail;
    // }
    // if (website == "") {
    //   newErrors.website = Strings.Pleaseenterawebsite;
    // }
    if (location1 == "") {
      newErrors.location1 = Strings.Pleaseenteralocation;
    }
    // if (bussImage == 0) {
    //   newErrors.bussImage = Strings.Pleaseenteraimage;
    // }
    // if (business_youtube == 0) {
    //   newErrors.business_youtube = Strings.Pleaseselectbusinessyoutube;
    // }
    // if (business_telegram == 0) {
    //   newErrors.business_telegram = Strings.Pleaseselectbusinesstelegram;
    // }
    // if (business_facebook == 0) {
    //   newErrors.business_facebook = Strings.Pleaseenterabusinessfacebook;
    // }
    // if (business_insta == 0) {
    //   newErrors.business_insta = Strings.Pleaseenterabusinessinsta;
    // }
    // if (business_linkedin == 0) {
    //   newErrors.business_linkedin = Strings.Pleaseenterbusinesslinkedin;
    // }
    // if (business_catalogue == 0) {
    //   newErrors.business_catalogue = Strings.Pleaseenterbusinesscatalogue;
    // }
    setErrors(newErrors);
    const errorKeys = Object.keys(newErrors);
    if (errorKeys.length > 0) {
      return;
    } else {
      let response;
      try {
        if (location && location.state && isEdit) {
          response = await Add_business_UpdateAPI({
            business_id: location.state?.data.business_id,
            business_name: business_name,
            concern_person: concern_person,
            description: description,
            category_id: category_id,
            address: address,
            pin: pin,
            city_id: city_id,
            country_id: country_id,
            contact_1: contact_1,
            contact_2: contact_2,
            email: email,
            website: website,
            location: location1,
            image_1_old: image_1_old,
            image_1: dataImage,
            business_youtube: business_youtube,
            business_telegram: business_telegram,
            business_facebook: business_facebook,
            business_insta: business_insta,
            business_linkedin: business_linkedin,
            business_catalogue: dataDocs,
            business_catalogue_old: business_catalogue_old,
            family_member_id: family_member_id,
          });
          if (response) {
            const id =  location.state?.data.business_id;
            const data = await UpdateImage2({
              id,
              bussImage
            })
          }          
        } else {
          response = await Add_business_detailsAPI({
            business_name: business_name,
            concern_person: concern_person,
            description: description,
            category_id: category_id,
            address: address,
            pin: pin,
            city_id: city_id,
            country_id: country_id,
            contact_1: contact_1,
            contact_2: contact_2,
            email: email,
            website: website,
            location: location1,
            image_1: dataImage,
            business_youtube: business_youtube,
            business_telegram: business_telegram,
            business_facebook: business_facebook,
            business_insta: business_insta,
            business_linkedin: business_linkedin,
            business_catalogue: dataDocs,
            family_member_id: family_member_id,
          });
        }
        if (response.ok === true) {
          Swal.fire({
            position: "center",
            icon: "success",
            title: response.message,
            showConfirmButton: true,
            timer: 2000,
          });
          navigate("/mybusiness");
        } else {
          Swal.fire({
            position: "center",
            icon: "error",
            title: response.message,
            showConfirmButton: true,
          });
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Something went wrong",
          showConfirmButton: true,
        });
      } finally {
        // setIsSubmitting(false); // Set submitting state back to false after API call completes
      }
    }
  };

  console.log('isEdit ', isEdit); 

  return (
    <div>
      <Box my={3} mb={6}>
        {/* <div>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 500, color: "#949494" }}
          >
            {Strings.ManageBusiness}
          </Typography>
        </div> */}

        <Box display="flex" alignItems="center" mt={2} mb={2}>
          {isEdit == true ? 
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackIcon />
        </IconButton>
        : "" }
        <Typography style={{ fontWeight: 600, marginLeft: '10px' }}>
          {Strings.ManageBusiness}
        </Typography>
      </Box>
        <Grid container spacing={3}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                </Typography>
              </div>

              <div style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <InputLabel
                  sx={{ fontSize: "small", fontWeight: "bold", width: "100%" }}
                >
                  {Strings.business_name}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.business_name}
                  onChange={(e) => {
                    setbusiness_name(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      business_name: "",
                    }));
                  }}
                  value={business_name}
                  defaultValue={
                    isEdit ? location.state?.data.business_name : ""
                  }
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.business_name && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.business_name}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.concern_person}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.concern_person}
                  onChange={(e) => {
                    setconcern_person(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      concern_person: "",
                    }));
                  }}
                  value={concern_person}
                  defaultValue={
                    isEdit ? location.state?.data.concern_person : ""
                  }
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.concern_person && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.concern_person}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.about_business_detail}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.about_business_detail}
                  onChange={(e) => {
                    setdescription(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      description: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                  value={description}
                  defaultValue={isEdit ? location.state?.data.description : ""}
                />
                {errors.description && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.description}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.business_category}
                </InputLabel>
                <Select
                  fullWidth
                  value={category_id}
                  onChange={(e) => {
                    setcategory_id(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      category_id: "",
                    }));
                  }}
                  defaultValue={{
                    value: isEdit ? location.state?.data.category_id : 0,
                    label: isEdit
                      ? location.state?.data.category_name
                      : Strings.select_business_category,
                  }}
                  displayEmpty
                  style={{
                    color: '#AEAFAF', // Adjust color as needed
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_business_category}
                  </MenuItem>
                  {BusinessCategorydata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.category_id && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.category_id}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.full_address}
                </InputLabel>
                <TextField
                  fullWidth
                  placeholder={Strings.full_address}
                  onChange={(e) => {
                    setaddress(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, address: "" }));
                  }}
                  value={address}
                  defaultValue={isEdit ? location.state?.data.address : ""}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.address && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.address}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.PIN}
                </InputLabel>
                <TextField
                  fullWidth
                  type="number"
                  placeholder={Strings.PIN}
                  onChange={(e) => {
                    setpin(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, pin: "" }));
                  }}
                  value={pin}
                  defaultValue={isEdit ? location.state?.data.pin : ""}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.pin && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.pin}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                    width: "100%",
                  }}
                >
                  {Strings.city}
                </InputLabel>
                <Select
                  fullWidth
                  value={city_id}
                  onChange={(e) => {
                    setcity_id(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, city_id: "" }));
                  }}
                  defaultValue={{
                    value: isEdit ? location.state?.data.city_id : 0,
                    label: isEdit
                      ? location.state?.data.city_name
                      : Strings.select_city,
                  }}
                  displayEmpty
                  style={{
                    color: '#AEAFAF', // Adjust color as needed
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.select_city}
                  </MenuItem>
                  {citydata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.city_id && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.city_id}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.country}
                </InputLabel>
                <Select
                  value={country_id}
                  onChange={(e) => {
                    setcountry_id(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      country_id: "",
                    }));
                  }}
                  defaultValue={{
                    value: isEdit ? location.state?.data.country_id : 0,
                    label: isEdit
                      ? location.state?.data.country_name
                      : Strings.country_select,
                  }}
                  displayEmpty
                  fullWidth
                  style={{
                    color: '#AEAFAF',
                    '& .MuiInput-underline:before': {
                      borderBottomColor: '#AEAFAF',
                    },
                    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                      borderBottomColor: '#AEAFAF',
                    },
                  }}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value={0} disabled>
                    {Strings.country_select}
                  </MenuItem>
                  {countrydata.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                {errors.country_id && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.country_id}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.contact_number1}
                </InputLabel>
                <TextField
                  type="number"
                  placeholder={Strings.contact_number1}
                  onChange={(e) => {
                    setcontact_1(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      contact_1: "",
                    }));
                  }}
                  value={contact_1}
                  defaultValue={isEdit ? location.state?.data.contact_1 : ""}
                  fullWidth
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.contact_1 && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.contact_1}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.contact_number2}
                </InputLabel>
                <TextField
                  type="number"
                  placeholder={Strings.contact_number2}
                  onChange={(e) => {
                    setcontact_2(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      contact_2: "",
                    }));
                  }}
                  value={contact_2}
                  defaultValue={isEdit ? location.state?.data.contact_2 : ""}
                  fullWidth
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.contact_2 && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.contact_2}
                  </Typography>
                )}
              </div>
            </div>
          </Grid>

          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <div style={{ padding: 16 }}>
              <div style={{ marginBottom: 8 }}>
                <Typography
                  variant="caption"
                  color="text"
                  fontWeight="bold"
                  textTransform="uppercase"
                >
                  {/* Your Caption Text Here */}
                </Typography>
              </div>

              <div style={{ listStyle: "none", margin: 0, padding: 0 }}>
                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.email}
                </InputLabel>
                <TextField
                  placeholder={Strings.email}
                  onChange={(e) => {
                    setemail(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, email: "" }));
                  }}
                  value={email}
                  defaultValue={isEdit ? location.state?.data.email : ""}
                  fullWidth
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.email && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.email}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.website}
                </InputLabel>
                <TextField
                  placeholder={Strings.website}
                  onChange={(e) => {
                    setwebsite(e.target.value);
                    setErrors((prevErrors) => ({ ...prevErrors, website: "" }));
                  }}
                  value={website}
                  defaultValue={isEdit ? location.state?.data.website : ""}
                  fullWidth
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                />
                {errors.website && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.website}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.location_google_map}
                </InputLabel>
                <TextField
                  placeholder={Strings.location_google_map}
                  onChange={(e) => {
                    setlocation1(e.target.value);
                    setErrors((prevErrors) => ({
                      ...prevErrors,
                      location1: "",
                    }));
                  }}
                  InputProps={{
                    style: {
                      color: '#888' ,
                    },
                  }}
                  value={location1}
                  defaultValue={isEdit ? location.state?.data.location : ""}
                  fullWidth
                />
                {errors.location1 && (
                  <Typography
                    variant="caption"
                    color="error"
                    sx={{ marginTop: "5px" }}
                  >
                    {errors.location1}
                  </Typography>
                )}

                <InputLabel
                  sx={{
                    fontSize: "small",
                    fontWeight: "bold",
                    marginTop: "14px !important",
                  }}
                >
                  {Strings.catelog_brochure}
                </InputLabel>
                <div style={{ position: "relative" }}>

                {/* <div style={{ position: "relative" }}> */}
                  <input
                    type="file"
                    id="fileInput"
                    accept="application/pdf" 
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      handleFileChange(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_catalogue: "",
                      }));
                    }}
                    InputProps={{
                      style: {
                        color: '#888888' ,
                      },
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      cursor: "pointer",
                      border: "1px solid #ccc",
                      padding: "18px 2px",
                      borderRadius: "8px",
                    }}
                    onClick={() => document.getElementById("fileInput").click()}
                  >
                    <span
                      style={{
                        color: "#888888",
                        fontSize: "15px",
                        padding: "10px",
                      }}
                    >
                      {business_catalogue || Strings.SelectCatelog}
                    </span>
                  </div>
                  {errors.business_catalogue && (
                    <Typography
                      variant="caption"
                      color="error"
                    >
                      {errors.business_catalogue}
                    </Typography>
                  )}
                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.business_image1}
                  </InputLabel>
                  <input
                    type="file"
                    id="fileInput1"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: 0,
                      opacity: 0,
                      cursor: "pointer",
                    }}
                    onChange={(e) => {
                      handlebusiness(e);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        bussImage: "",
                      }));
                    }}
                    InputProps={{
                      style: {
                        color: '#888888' ,
                      },
                    }}
                  />
                  <div
                    style={{
                      position: "relative",
                      zIndex: 1,
                      cursor: "pointer",
                      border: "1px solid #ccc",
                      padding: "18px 2px",
                      borderRadius: "8px",
                    }}
                    onClick={() => document.getElementById("fileInput1").click()}
                  >
                    <span
                      style={{
                        color:  "#888888",
                        fontSize: "15px",
                        padding: "10px",
                      }}
                    >
                      { bussImage || Strings.SelectImage}
                    </span>
                  </div>
                  {errors.bussImage && (
                    <Typography
                      variant="caption"
                      color="error"
                    >
                      {errors.bussImage}
                    </Typography>
                  )}

                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.youtube_link}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={1}
                    placeholder={Strings.youtube_link}
                    onChange={(e) => {
                      setbusiness_youtube(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_youtube: "",
                      }));
                    }}
                    defaultValue={
                      isEdit ? location.state?.data.business_youtube : ""
                    }
                    InputProps={{
                      style: {
                        color: '#888' ,
                      },
                    }}
                    fullWidth
                  />
                  {errors.business_youtube && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "5px" }}
                    >
                      {errors.business_youtube}
                    </Typography>
                  )}

                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.facebook_link}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={1}
                    placeholder={Strings.facebook_link}
                    onChange={(e) => {
                      setbusiness_facebook(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_facebook: "",
                      }));
                    }}
                    InputProps={{
                      style: {
                        color: '#888' ,
                      },
                    }}
                    defaultValue={
                      isEdit ? location.state?.data.business_facebook : ""
                    }
                    fullWidth
                  />
                  {errors.business_facebook && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "5px" }}
                    >
                      {errors.business_facebook}
                    </Typography>
                  )}

                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.linkedin_link}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={1}
                    placeholder={Strings.linkedin_link}
                    onChange={(e) => {
                      setbusiness_linkedin(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_linkedin: "",
                      }));
                    }}
                    defaultValue={
                      isEdit ? location.state?.data.business_linkedin : ""
                    }
                    fullWidth
                    InputProps={{
                      style: {
                        color: '#888' ,
                      },
                    }}
                  />
                  {errors.business_linkedin && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "5px" }}
                    >
                      {errors.business_linkedin}
                    </Typography>
                  )}

                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.instagram_link}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={1}
                    placeholder={Strings.instagram_link}
                    onChange={(e) => {
                      setbusiness_insta(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_insta: "",
                      }));
                    }}
                    defaultValue={
                      isEdit ? location.state?.data.business_insta : ""
                    }
                    fullWidth
                    InputProps={{
                      style: {
                        color: '#888' ,
                      },
                    }}
                  />
                  {errors.business_insta && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "5px" }}
                    >
                      {errors.business_insta}
                    </Typography>
                  )}

                  <InputLabel
                    sx={{
                      fontSize: "small",
                      fontWeight: "bold",
                      marginTop: "14px !important",
                    }}
                  >
                    {Strings.telegram_link}
                  </InputLabel>
                  <TextField
                    multiline
                    rows={1}
                    placeholder={Strings.telegram_link}
                    onChange={(e) => {
                      setbusiness_telegram(e.target.value);
                      setErrors((prevErrors) => ({
                        ...prevErrors,
                        business_telegram: "",
                      }));
                    }}
                    defaultValue={
                      isEdit ? location.state?.data.business_telegram : ""
                    }
                    fullWidth
                    InputProps={{
                      style: {
                        color: '#888' ,
                      },
                    }}
                  />
                  {errors.business_telegram && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ marginTop: "5px" }}
                    >
                      {errors.business_telegram}
                    </Typography>
                  )}
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <div style={{ padding: "8px 0", textAlign: "center" }}>
            {isEdit ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
              >
                {Strings.update_manage_business}
              </Button>
            ) : (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={isSubmitting}
              >
                {isSubmitting ? Strings.Adding : Strings.add_manage_business}
              </Button>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
}
