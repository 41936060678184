import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { VideoGalleryAPI } from "../Features/services";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Videogallery() {
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await VideoGalleryAPI({ offset });
        if (Array.isArray(response) && response.length > 0) {
          setData(response);
        } else {
          setData([]);
        }
      } catch (error) {
        setError("Error fetching video gallery data: " + error.message);
      }
    };

    fetchData();
  }, [offset]);

  const handleVideoClick = (videoLink) => {
    const videoId = videoLink.split("v=")[1].split("&")[0];
    setSelectedVideo(videoId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedVideo(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <>
      <Box mt={5} mb={6}>
        <Grid container spacing={3}>
          {data.map((item, index) => (
            <Grid item sm={4} key={index} style={{ cursor: "pointer" }}>
              <Card onClick={() => handleVideoClick(item.video_gallery_link)}>
                <CardMedia
                  component="img"
                  height="300"
                  image={`https://img.youtube.com/vi/${item.video_gallery_link.split("v=")[1].split("&")[0]}/hqdefault.jpg`}
                  alt=""
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog fullScreen open={openModal} onClose={handleCloseModal} TransitionComponent={Transition}>
        <DialogContent>
          <Box display="flex" justifyContent="flex-end">
            <IconButton edge="end" color="inherit" onClick={handleCloseModal} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box display="flex" justifyContent="center">
            {selectedVideo && (
              <iframe
                title="video"
                width="1000"
                height="600"
                src={`https://www.youtube.com/embed/${selectedVideo}`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Videogallery;
