import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import store, { persistor } from './Features/store'; // Importing store and persistor separately
import App from './App';
// import { BesnuProvider } from './CustomeHook/BesnuContext';

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
    {/* <BesnuProvider> */}
      <App />
      {/* </BesnuProvider> */}
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);
