import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import { about_JMAPI } from '../Features/services';
import '../styles/AboutUs.scss'; // Import the external CSS file

const About = () => {
  const [data, setData] = useState('');
  const [title, setTitle] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const privacyResponse = await about_JMAPI({});
      if (privacyResponse.ok === true) {
        setData(parse(privacyResponse.data[0].content));
        setTitle(privacyResponse.data[0].title);
      }
    })();
  }, []);

  const handleBackClick = () => {
      navigate(-1);
  };

  return (
    <>
    <Box className="about-container">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box className="about-header">
            <IconButton onClick={handleBackClick} color='primary'>
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="h5" component="div">
              {title}
            </Typography>
          </Box>
          <Card className="about-card">
            <Typography variant="body2" color="textSecondary" component="div">
              {data}
            </Typography>
          </Card>
        </Grid>
      </Grid>
    </Box>
    </>
  );
};

export default About;
