import { useState, useEffect } from "react";
import { Grid, Box, Typography, Card, Button, IconButton } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { View_List_Of_Villages_API } from "../Features/services";
import RoomIcon from "@mui/icons-material/Room";
import { Strings } from "../Common/String";
import nodatafound from '../assets/background/nodatafound.png';

function Native() {
  const navigate = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);

  useEffect(() => {
    (async () => {
      const CategoryResponse = await View_List_Of_Villages_API({
        offset: offset,
      });
      if (CategoryResponse?.ok === true) {
        if (CategoryResponse?.data?.length > 0) {
          setData(CategoryResponse.data);
        } else {
          setData([]);
        }
      } else {
        setData(CategoryResponse);
      }
    })();
  }, [offset]);

  const handleCardClick = (data) => {
    navigate(`/familyheadlist`, {
      state: { data: data },
    });
  };

  return (
    <Box py={3}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h5">
          {Strings.Native}
        </Typography>
      </Box>
      <br />
      <Box mb={3}>
        {data && data.length > 0 ? (
          <Grid container spacing={3}>
            {data.map((val) => (
              <Grid item xs={12} sm={6} xl={4} key={val.village_id} onClick={() => handleCardClick(val)}>
                <Card sx={{ backgroundColor: '#FAF9F6' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" p={2}>
                    <Box display="flex" alignItems="center">
                      <Box>
                        <Typography variant="h6">{val.village_name}</Typography>
                        <Typography variant="subtitle2" color="textSecondary">
                          {val.total}
                        </Typography>
                      </Box>
                    </Box>
                    <IconButton>
                      <RoomIcon color="error" />
                    </IconButton>
                  </Box>
                </Card>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            height="100px"
          >
            <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
          </Box>
        )}
      </Box>
      <Box mb={3}>
        <Grid container spacing={3}>
          {offset > 0 && (
            <Grid item xs={12} sm={6} xl={3} onClick={() => setOffset(offset - 30)}>
              <Button variant="outlined" color="info" size="small" sx={{ width: "5%", marginRight: "90%" }}>
                {Strings.Prev}
              </Button>
            </Grid>
          )}
          {data && data.length > 29 && (
            <Grid item xs={12} sm={6} xl={3} onClick={() => setOffset(offset + 30)}>
              <Button variant="outlined" color="info" size="small" sx={{ width: "5%", marginLeft: "280%" }}>
                {Strings.Next}
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default Native;
