import React, { useEffect, useState } from "react";
import "../styles/Besnu.scss";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Imageurl } from "../Features/apisUrl";
import { BesanuAPI } from "../Features/services";
import { Box, Typography } from "@mui/material";
import BesnuDetail from "./BesnuDetail";
import { Link, useNavigate } from "react-router-dom";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { Strings } from "../Common/String";
import nodatafound from '../assets/background/nodatafound.png';

export default function Besnu() {
  const navigate = useNavigate();
  const [besnuData, setBesnuData] = useState([]);
  const [offset, setoffset] = useState(0);
  // const [data, setdata] = useState(null);
  // const [modal, setmodal] = useState(false);
  // const [data, setData] = useState(null);
  // const [selectedData, setSelectedData] = useState(null);
  // const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      const CategoryResponce = await BesanuAPI({
        offset: offset,
      });
      if (CategoryResponce?.ok === true) {
        if (CategoryResponce?.data?.length > 0) {
          setBesnuData(CategoryResponce.data);
        } else {
          setBesnuData([]);
        }
      } else {
        setBesnuData(CategoryResponce);
      }
    })();
  }, [offset]);

  // const handleChange = (e, temp) => {
  //   console.log('data 1', temp)
  //   setdata(temp);
  //   setmodal(true);
  //   // navigate('/besnudetail');
  // };

  const handleCardClick = (datas) => {
    navigate("/besnudetails", { state: datas });
  };

  return (
    <>
      <Typography className="besnu">{Strings.Besnu}</Typography>
      <div className="welcome-pages">
        <div className="contents">
          <div className="columns">
            {besnuData && besnuData.length > 0 ? (
              <div className="deaths-containers">
                {besnuData.map((datas) => (
                  <Card
                    key={datas.id}
                    className="besnu-card"
                    onClick={() => handleCardClick(datas)}
                  >
                    <CardMedia
                      component="img"
                      height="500"
                      // style={{ width: '0px !important' }}
                      image={Imageurl.besanu_image + datas.besanu_image}
                      alt="Img Not Found"
                    />
                  </Card>
                ))}
              </div>
            ) : (
              <Box className="no-data-container">
                 <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
                {/* <SentimentVeryDissatisfiedIcon className="no-data-icon" />
                <Typography variant="h6" className="no-data-text">
                  {Strings.NoDataFound}
                </Typography> */}
              </Box>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
