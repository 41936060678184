import React, { useEffect, useState } from "react";
import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import { NewsAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import parse from 'html-react-parser';
import truncate from 'truncate-html';
import { useNavigate } from "react-router-dom";
import { Strings } from "../Common/String";
import nodatafound from '../assets/background/nodatafound.png';

const News = () => {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const navigate = useNavigate();

  const handleLearnMore = (e, data) => {
    navigate(`/newsscreendetail`, {
      state: { data: data },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await NewsAPI({ offset });
        if (response?.length > 0) {
          setData(response);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching news data:", error);
        setData([]);
      }
    };

    fetchData();
  }, [offset]);

  return (
    <div>
      <Box sx={{ marginTop: '10px', marginBottom: '30px' }}>
        <Typography style={{ fontSize: '28px', fontWeight: 500, color: '#333333' }} gutterBottom>
          {Strings.News}
        </Typography>
      </Box>
      <Grid container spacing={3} justifyContent="center" mb={10}>
      {data && data.length > 0 ? (
        data.map((news) => (
          <Grid item key={news.news_id} xs={12} sm={6} md={4}>
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
              }}
            >
              <CardActionArea onClick={(e) => handleLearnMore(e, news)} sx={{ flexGrow: 1 }}>
                <CardMedia
                  component="img"
                  image={Imageurl.news_image + news.news_image}
                  alt={news.news_title}
                  sx={{
                    height: 160,
                    objectFit: 'cover',
                  }}
                />
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {news.news_title}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {parse(truncate(news.news_desc, { length: 50, stripTags: true }))}...
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <Button size="small" color="primary" onClick={(e) => handleLearnMore(e, news)}>
                  {Strings.LearnMore}
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))) 
        : 
        <Box className="no-data-container">
                 <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
              </Box>
        }
      </Grid>
    </div>
  );
};

export default News;
