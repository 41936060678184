import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Avatar from '@mui/material/Avatar';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { View_All_JM_Member_API } from '../Features/services';
import { Imageurl } from '../Features/apisUrl';
import { Strings } from '../Common/String';
import nodatafound from '../assets/background/nodatafound.png';
import PersonIcon from '@mui/icons-material/Person';
import DefaultProfileIcon from "@mui/icons-material/AccountCircle";

function JMMembers() {
  const [rows, setRows] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Number of rows per page
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, [page, rowsPerPage]);

  const fetchData = async () => {
    try {
      const view_donationResponse = await View_All_JM_Member_API({
        offset: page * rowsPerPage,
        limit: rowsPerPage,
      });
      if (view_donationResponse.data) {
        setRows(view_donationResponse.data);
      } else {
        setRows([]);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleImageClick = (index, image) => {
    if(image?.photo) {
      setSelectedImage(image?.photo);
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30}} />;

  const paginatedRows = rows.slice(page * rowsPerPage, (page + 1) * rowsPerPage);

  return (
    <Box py={3}>
      <Box mb={6}>
        <Box display="flex" alignItems="center" mb={2}>
          <ArrowBackIcon onClick={handleBackClick} style={{ cursor: 'pointer' }} />
          <Typography variant="h5" component="h2" sx={{ ml: 2 }}>
            {Strings.Alljmmembers}
          </Typography>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center">{Strings.Photo}</TableCell>
                <TableCell align="center">{Strings.Name}</TableCell>
                <TableCell align="center">{Strings.Native}</TableCell>
                <TableCell align="center">{Strings.Contact}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows && rows.length > 0 ? (
                paginatedRows.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Box display="flex" alignItems="center">
                        <Tooltip title={`${row.first_name} ${row.last_name}`} placement="bottom">
                            <Avatar
                                src={Imageurl.member_image + row.photo}
                                alt={row.first_name}
                                sx={{
                                  cursor: "pointer",
                                  "&:hover, &:focus": {
                                    zIndex: "10",
                                  },
                                }}
                                onClick={() => handleImageClick(index, row)}
                              >
                                {defaultProfileImage}
                              </Avatar>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{`${row.surname == null ? '' : row.surname} 
                      ${row.first_name == null ? '' : row.first_name} ${row.last_name == null ? '' : 
                      row.last_name
                    }`}</TableCell>
                    <TableCell align="center">{row.village_name == null ? '' : row.village_name}</TableCell>
                    <TableCell align="center">{row.contact == null ? '' : row.gender == 'female' ? '' : row.contact}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <Box
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      alignItems="center"
                      p={3}
                    >
                      <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
      <Dialog open={open} onClose={handleCloseModal}>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            {selectedImage ? (
              <Avatar src={Imageurl.member_image + selectedImage} 
              alt="Member Photo" 
              sx={{ width: 200, height: 200 }} />
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <PersonIcon style={{ fontSize: 80, color: "gray" }} />
            </Box>
            )}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default JMMembers;
