import React, { useEffect, useState } from "react";
import { PhotoGalleryAPI, SliderAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../styles/MySlider.scss"

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 0
      }
    }
  ]
};

const MySlider = () => {
  const [photoData, setPhotoData] = useState([]);

  useEffect(() => {
    const fetchPhotoData = async () => {
      try {
        const response = await SliderAPI({ offset: 0 });
        setPhotoData(response || []);
      } catch (error) {
        console.error("Error fetching photo data:", error);
        setPhotoData([]);
      }
    };

    fetchPhotoData();
  }, []);

  return (
    <div>
      <Slider {...settings}>
        {photoData.map((photo, index) => (
          <div key={index}>
            <img src={Imageurl.slider_images + photo.slider_image} alt={`Slide ${index}`} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default MySlider;
