import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import { App_detailAPI } from '../Features/services';
import parse from 'html-react-parser';

function History() {
  const [data, setData] = useState('');
  const [title, setTitle] = useState('');
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const privacyResponse = await App_detailAPI({
          type: '3',
        });
        if (privacyResponse.ok === true) {
          setData(parse(privacyResponse.data[0]?.content || ''));
          setTitle(parse(privacyResponse.data[0]?.title || ''));
        }
      } catch (error) {
        console.error('Error fetching app details:', error);
      }
    };

    fetchData();
  }, []);

  return (
      <Grid container mt={5} mb={3}>
        <Grid item sx={{ width: '100% !important' }}>
          <Card style={{ marginBottom: '30px' }}>
            <Grid container spacing={2} p={2}>
              <Grid item sx={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h5">{title}</Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1">{data}</Typography>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
  );
}

export default History;
