import { Card, Grid, Typography, Box, Button } from "@mui/material";
import Icon from "@mui/material/Icon";
import { Strings } from "../Common/String";

function DashboardAbout() {
  return (
    <Card>
      <Box p={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={12}>
            <Box display="flex" flexDirection="column" height="100%">
              <Box pt={1} mb={0.5}>
                {/* <Typography variant="body2" color="textPrimary" fontWeight="bold">
                  {Strings.about_us}
                </Typography> */}
              </Box>
              <Typography variant="h5" fontWeight="bold" gutterBottom>
                {Strings.AboutUs}
              </Typography>
              <Box mb={6}>
                <Typography sx={{ fontSize: '20px' }} variant="body2" color="textSecondary">
                  {Strings.DashboardAbout}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default DashboardAbout;
