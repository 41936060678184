import { createSlice } from "@reduxjs/toolkit";

const reducerSlice = createSlice({
  name: "country",
  initialState: {
    countryData: [],
    materialData: [],
    viewVillage: [],
    pragatiData: [],
    addmemberData: [],
    updatememberData: [],
    relationData: [],
    visaData: [],
    educationData: [],
    educationSubtypeData: [],
    occupationData: [],
    bloodgroupData: [],
    cityData: [],
    sakhdata: [],
    // viewBusinessCategoryData: []
  },
  reducers: {
    setCountryData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.countryData = action.payload;
      }
    },
    setmaterialData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.materialData = action.payload;
      }
    },
    setviewVillageData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.viewVillage = action.payload;
      }
    },
    setpragatiData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.pragatiData = action.payload;
      }
    },
    setaddmemberData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.addmemberData = action.payload;
      }
    },
    setupdatememberData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.updatememberData = action.payload;
      }
    },
    setrelationData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.relationData = action.payload;
      }
    },
    setvisaData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.visaData = action.payload;
      }
    },
    seteducationData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.educationData = action.payload;
      }
    },
    seteducationSubtypeData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.educationSubtypeData = action.payload;
      }
    },
    setoccupationData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.occupationData = action.payload;
      }
    },
    setbloodgroupData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.bloodgroupData = action.payload;
      }
    },
    setcityData(state, action) {
      if (action.payload && action.payload.length > 0) {
        state.cityData = action.payload;
      }
    },
    setsakhData(states, action) {
    if (action.payload && action.payload.length > 0) {
      states.sakhdata = action.payload;
    }
  },
    // setviewBusinessCategoryData(state, action) {
    //   if (action.payload && action.payload.length > 0) {
    //     state.viewBusinessCategoryData = action.payload;
    //   }
    // },
  },
});

export const {
  setCountryData,
  setmaterialData,
  setviewVillageData,
  setpragatiData,
  setaddmemberData,
  setupdatememberData,
  setrelationData,
  setvisaData,
  seteducationData,
  seteducationSubtypeData,
  setoccupationData,
  setbloodgroupData,
  setcityData,
  setsakhData,
  // setviewBusinessCategoryData
} = reducerSlice.actions;

export default reducerSlice.reducer;
