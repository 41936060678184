import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import { Skeleton } from "@mui/material";
import parse from "html-react-parser";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Typography } from "@mui/material";
import { Imageurl } from "../Features/apisUrl";
import "../styles/BesnuData.scss";

const BesnuData = () => {
  const [besnu, setBesnu] = useState(null);
  const location = useLocation();
  const content = location?.state?.temp || {};
  const navigate = useNavigate();

  useEffect(() => {
    if (content) {
      setBesnu(content);
    }
  }, [content]);

  if (!besnu) {
    return (
      <div>
        <Header />
        <div className="news-page-bd">
          <Skeleton animation="wave" variant="rectangular" width="100%" height={200} />
          <Skeleton animation="wave" height={40} />
          <Skeleton animation="wave" height={20} width="80%" />
          <Skeleton animation="wave" height={20} width="70%" />
          <Skeleton animation="wave" height={20} width="90%" />
        </div>
        <Footer />
      </div>
    );
  }

  return (
    <div>
      <Header />
      <div className="news-page-bd">
        <div className="news-content-bd">
          <div className="news-scroll-container-bd">
            <div className="header-container-bd">
              <IconButton onClick={() => navigate(-1)} color="primary">
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h5" className="title-bd">
                {besnu.name}
              </Typography>
            </div>
            <h3>{besnu.death_date}</h3>
            <img
              className="news-image-bd"
              src={Imageurl.besanu_image + besnu.besanu_image}
              alt='Img Not Found'
            />
            <div className="news-desc-bd-1">{parse(besnu.besanu_description)}</div>
            <div className="news-desc-bd" style={{ fontWeight: 600 }}>
              {besnu.besanu_date + ' ' + besnu.besanu_time}
            </div>
            <div className="news-desc-bd-2" style={{ fontWeight: 600 }}>
              {besnu.besanu_place}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default BesnuData;
