import React, { useEffect, useState } from 'react'
import Header from './Header'
import Footer from './Footer'
import "../styles/PrivacyPolicy.scss";
import { TermsAPI } from '../Features/services';
import parse from 'html-react-parser';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';

export default function TermsConditions() {
    const [data, setdata] = useState([]);
    const [offset, setoffset] = useState(5);
    const navigate = useNavigate();
  
    useEffect(() => {
      (async () => {
        const CategoryResponce = await TermsAPI({
          type: offset,
        });
        if (CategoryResponce?.ok === true) {
          if (CategoryResponce?.data?.length > 0) {
            setdata(CategoryResponce.data);
          } else {
            setdata([]);
          }
        } else {
          setdata(CategoryResponce);
        }
      })();
    }, [offset]);

  return (
    <div>
    <Header />
    <div className="content-policy">
      <div className="header-container-policy">
        <ArrowBackIcon 
          className="back-arrow-policy"
          style={{ cursor: 'pointer', fontSize: 30 }}
          onClick={() => navigate(-1)}
        />
        {data.length > 0 && <h1 className="title-policy">{data[0].title}</h1>}
      </div>
      {data && data.map((item) => (
        // <div key={item.id}>
          <p className='datacontent-policy'>{parse(item.content)}</p>
        // </div>
      ))}
    </div>
    <Footer />
  </div>
  )
}
