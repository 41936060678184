import React, { useState } from "react";
import { Grid, Box, Card, Typography, TextField, Button } from "@mui/material";
import Swal from "sweetalert2";
import { InquiryAPI } from "../Features/services";
import { Strings } from "../Common/String";

function Query() {
  const [inquiry_title, setInquiryTitle] = useState("");
  const [inquiry_message, setInquiryMessage] = useState("");
  const [contact, setContact] = useState("");
  const [validationText, setValidationText] = useState("");
  const [check_response, setCheckResponse] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await InquiryAPI({
          inquiry_title: inquiry_title,
          inquiry_message: inquiry_message,
          contact: contact,
        });

        if (response && response.ok === true) {
          Swal.fire({
            icon: "success",
            title: Strings.InquirySubmitted,
            showConfirmButton: false,
            timer: 2000,
          });
          setCheckResponse(true);
          setInquiryTitle("");
          setInquiryMessage("");
          setContact("");
        } else {
          Swal.fire({
            icon: "error",
            title: Strings.InquiryNotSubmitted,
            showConfirmButton: false,
            timer: 2000,
          });
        }
      } catch (error) {
        console.error("Error submitting inquiry:", error);
        Swal.fire({
          icon: "error",
          title: Strings.Errorsubmittinginquiry,
          text: error.message || Strings.Unknownerror,
          showConfirmButton: false,
          timer: 2000,
        });
      }
    }
  };

  const validateForm = () => {
    if (!contact) {
      setValidationText(Strings.validation_msg_contact);
      return false;
    } else if (!inquiry_title) {
      setValidationText(Strings.enter_title);
      return false;
    } else if (!inquiry_message) {
      setValidationText(Strings.enter_message);
      return false;
    }
    setValidationText("");
    return true;
  };

  return (
    <Box mt={5} mb={3}>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={8} lg={6}>
          <Card elevation={3}>
            <Box p={4}>
              <Typography variant="h5" align="center" gutterBottom>
                {Strings.inquiry}
              </Typography>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={Strings.phone_number}
                      variant="outlined"
                      type="number"
                      value={contact}
                      onChange={(e) => setContact(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={Strings.title_colon}
                      variant="outlined"
                      value={inquiry_title}
                      onChange={(e) => setInquiryTitle(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label={Strings.message}
                      variant="outlined"
                      multiline
                      rows={4}
                      value={inquiry_message}
                      onChange={(e) => setInquiryMessage(e.target.value)}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {validationText && (
                      <Typography variant="body2" color="error">
                        {validationText}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      fullWidth
                      onClick={handleSubmit}
                    >
                      {Strings.submit}
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Query;
