import React, { useEffect, useState } from "react";
import { App_detailAPI } from "../Features/services";
import { Card, CardContent, Grid, Typography } from "@mui/material";
import parse from "html-react-parser";


const DashboardContact = () => {
    const [contactData, setContactData] = useState({
      title: "",
      content: ""
    });
    const [privacyData, setPrivacyData] = useState({
      title: "",
      content: ""
    });
  
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const privacyResponse = await App_detailAPI({
            type: "4",
          });
          if (privacyResponse.ok === true && privacyResponse.data.length > 0) {
            setPrivacyData({
              title: privacyResponse.data[0].title,
              content: parse(privacyResponse.data[0].content)
            });
          } else {
            console.error("Privacy data not found");
          }

          const contactResponse = await App_detailAPI({
            type: "3",
          });
          if (contactResponse.ok === true) {
            setContactData({
              title: parse(contactResponse.data[0]?.title || ''),
              content: parse(contactResponse.data[0]?.content || '')
            });
          } else {
            console.error("Contact us data not found");
          }
        } catch (error) {
          console.error("Error fetching privacy data:", error);
        }
      };
  
      fetchData();
    }, []);

  return (
    <div style={{ marginTop: '5%' }}>
      {/* <h2>For More Information</h2> */}
      <Grid container spacing={3} mb={12}>
        {/* First Column for History or Privacy Card */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "16px", width: "100%", height: "100%" }}>
            <CardContent sx={{ height: "100%", overflowY: "auto" }}>
              <Typography variant="h6" component="h1" gutterBottom>
                {privacyData.title}
              </Typography>
              <Typography variant="body1">
                {privacyData.content}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Second Column for Contact Us Card */}
        <Grid item xs={12} sm={6}>
          <Card sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "16px", width: "100%", height: "100%" }}>
            <CardContent sx={{ height: "100%", overflowY: "auto" }}>
              <Typography variant="h6" component="h1" gutterBottom>
                {contactData.title}
              </Typography>
              <Typography variant="body1">
                {contactData.content}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

export default DashboardContact;
