import { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Box,
  Card,
  CardContent,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Strings } from "../Common/String";
import { BesanuAPI, CountAPI } from "../Features/services";
import DashboardAbout from "./DashboardAbout";
import HomeWorkIcon from "@mui/icons-material/HomeWork";
import HandshakeIcon from "@mui/icons-material/Handshake";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import FamilyRestroomIcon from "@mui/icons-material/FamilyRestroom";

function Dashboard() {
  const history = useNavigate();
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [countdata, setCountData] = useState('');
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const CategoryResponce = await BesanuAPI({ offset: offset });
        if (CategoryResponce?.ok === true) {
          if (CategoryResponce?.data?.length > 0) {
            setData(CategoryResponce.data);
          } else {
            setData([]);
          }
        } else {
          setData([]);
        }
      } catch (error) {
        console.error("Error fetching Besanu data:", error);
        setData([]);
      }
    }
    fetchData();
  }, [offset]);

  useEffect(() => {
    async function fetchCountData() {
      try {
        const countResponse = await CountAPI({});
        if (countResponse.ok === true && countResponse.data) {
          setCountData(countResponse.data);
        } else {
          setCountData('');
        }
      } catch (error) {
        console.error("Error fetching count data:", error);
        setCountData('');
      }
    }
    fetchCountData();
  }, []);

  // const handleOpen = (image) => {
  //   setOpen(true);
  //   setSelectedImage(image);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const formatDate = (dateStr) => {
  //   const [year, month, day] = dateStr.split('-');
  //   return `${day}/${month}/${year}`;
  // };

  // const handleCardClick = (data) => {
  //   history(`/besanu-details`, {
  //     state: { data: data },
  //   });
  // };

  return (
    <>
      <Grid item xs={12} sm={6} xl={3} mt={3} mb={2}>
       <Typography style={{ fontSize: '20px', fontWeight: 500, color: '#302929' }}>{Strings.Dashboard}</Typography>
      </Grid>
      <Box py={3} mb={2}>
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <CardContent style={{ display: "flex", alignItems: "center" }}>
                  <HomeWorkIcon sx={{ fontSize: 50, color: "#3f51b5", marginRight: 3 }} />
                  <Typography component="div">
                    {Strings.village}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {countdata.total_village}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <CardContent style={{ display: "flex", alignItems: "center" }}>
                  <HandshakeIcon sx={{ fontSize: 50, color: "#f50057", marginRight: 3 }} />
                  <Typography component="div">
                    {Strings.marraige_beuro}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {countdata.total_matrimonial}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <CardContent style={{ display: "flex", alignItems: "center" }}>
                  <BusinessCenterIcon sx={{ fontSize: 50, color: "#4caf50", marginRight: 3 }} />
                  <Typography component="div">
                    {Strings.business}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {countdata.total_business}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} xl={3}>
              <Card>
                <CardContent style={{ display: "flex", alignItems: "center" }}>
                  <FamilyRestroomIcon sx={{ fontSize: 50, color: "#ff9800", marginRight: 3 }} />
                  <Typography component="div">
                    {Strings.family}
                  </Typography>
                </CardContent>
                <CardContent>
                  <Typography variant="body2" color="text.secondary">
                    {countdata.total_families}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
        {localStorage.getItem("approve_status") === "1" && (
          <marquee style={{ color: "#EE4E34" }} direction="left">
            {Strings.msg_marquee}
          </marquee>
        )}
        <Box mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={12}>
              <DashboardAbout />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
