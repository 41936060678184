import { useState, useEffect } from "react";
import {
  Card,
  IconButton,
  Box,
  Button,
  Avatar,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
  Drawer,
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { Strings } from "../Common/String";
import { View_members_by_family_codeAPI, ViewBloodsListAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import PersonIcon from "@mui/icons-material/Person";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import nodatafound from "../assets/background/nodatafound.png";

function BloodDonorList() {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [fullData, setFullData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMember, setSelectedMember] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const blood = location.state?.data || null;

  useEffect(() => {
    (async () => {
      const CategoryResponse = await ViewBloodsListAPI({
        offset: page * rowsPerPage,
        blood_group: blood.group_name,
      });
      if (CategoryResponse?.length > 0) {
        setRows(CategoryResponse.slice(0, rowsPerPage));
        setFullData(CategoryResponse);
      } else {
        setRows([]);
        setFullData([]);
      }
    })();
  }, [page, rowsPerPage]);

  const handleOpen = (id, photo) => {
    const member = fullData.find((item) => item.id === id); // Assuming `id` is a unique identifier
    setSelectedMember(photo);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewDetails = async (data) => {
    const response = await View_members_by_family_codeAPI({
      offset: 0,
      family_code: data?.family_code,
    });
    navigate("/memberdetail", { state: { data, response } });
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  return (
    <Card style={{ marginBottom: "30px" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" p={3}>
        <Box style={{ marginTop: "2%", display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom>
            {Strings.MemberListOf + blood.group_name + Strings.BloodGroup}
          </Typography>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"><strong>{Strings.Photo}</strong></TableCell>
              <TableCell align="center"><strong>{Strings.Name}</strong></TableCell>
              <TableCell align="center"><strong>{Strings.City}</strong></TableCell>
              <TableCell align="center"><strong>{Strings.Contact}</strong></TableCell>
              <TableCell align="center"><strong>{Strings.Alive}</strong></TableCell>
              <TableCell align="center"><strong>{Strings.Action}</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.id}>
                <TableCell align="center">
                  <Box display="flex" justifyContent="center">
                    <Avatar
                      src={Imageurl.member_image + row.photo}
                      alt={row.first_name}
                      sx={{
                        cursor: "pointer",
                        "&:hover, &:focus": {
                          zIndex: "10",
                        },
                      }}
                      onClick={() => handleOpen(row.id, row.photo)} // Pass unique id
                    >
                      {defaultProfileImage}
                    </Avatar>
                  </Box>
                </TableCell>
                <TableCell align="center">
                  {row.first_name || '-'} {row.surname || '-'}
                </TableCell>
                <TableCell align="center">{row.village_name || '-'}</TableCell>
                <TableCell align="center">
                  {row.gender === "female" ? "-" : row.contact || '-'}
                </TableCell>
                <TableCell align="center">
                  {row.death_status === 1 ? 
                   <Button variant="contained" color="error" style={{ width: '20px', height: '20px', color: 'white', fontSize: '10px', paddingTop: '8px' }}>DEAD</Button>
                  : "ALIVE"}
                </TableCell>
                <TableCell align="center">
                  <IconButton onClick={() => handleViewDetails(row)}>
                    <RemoveRedEyeIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {rows.length === 0 && (
        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" p={3}>
          <img src={nodatafound} alt="No Data Found" style={{ width: "500px", height: "auto" }} />
        </Box>
      )}
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={fullData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          style: {
            width: 500,
            zIndex: 1500,
            height: "100%",
            position: "fixed",
          },
        }}
      >
        {/* Drawer content goes here */}
      </Drawer>
      {selectedMember && (
        <Dialog open={open} onClose={handleClose}>
          <DialogContent>
            {selectedMember ? (
              <img
                src={`${Imageurl.member_image}${selectedMember}`}
                alt="Member's Photo"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            ) : (
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <PersonIcon style={{ fontSize: 80, color: "gray" }} />
              </Box>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Card>
  );
}

export default BloodDonorList;
