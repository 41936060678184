import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { useLocation, useNavigate } from 'react-router-dom';
import { Bloodgroup_API } from '../Features/services';
import PinDropIcon from '@mui/icons-material/PinDrop';
import { Box } from '@mui/material';
import { Strings } from '../Common/String';

function Blooddonors() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const bloodResponce = await Bloodgroup_API();
        if (bloodResponce.ok && bloodResponce.data?.length > 0) {
          setData(bloodResponce.data);
        } else {
          setData([]);
        }
      } catch (error) {
        console.error('Error fetching blood groups:', error);
        setData([]);
      }
    };
    fetchData();
  }, []);

  const handleCardClick = (e, selectedData) => {
    // console.log('selectedData', e, selectedData);
    navigate(`/blooddonorlist`, {
      state: { data: selectedData },
    });
  };

  return (
    <>
    <Box style={{ marginTop: "2%" }}>
        <Typography style={{ fontWeight: 500, fontSize: "22px" }}>
          {Strings.BloodGroupList}
        </Typography>
      </Box>
      {/* <hr /> */}
      <Grid container spacing={3} mt={4} mb={4}>
        {data.map((bloodGroup) => (
          <Grid item xs={12} sm={6} xl={3} key={bloodGroup.group_id}>
            <Card
              style={{ cursor: 'pointer', backgroundColor: '#FAF9F6' }}
              onClick={(e) => handleCardClick(e, bloodGroup)}
            >
              <Grid container alignItems="center">
                <Grid item xs={2}>
                <PinDropIcon style={{ fontSize: 60, color: 'darkred' }} />
                </Grid>
                <Grid item xs={10}>
                  <Typography variant="h6">{bloodGroup.group_name}</Typography>
                  <Typography variant="body2">{Strings.MoreInformationClickHere}</Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      </>
  );
}

export default Blooddonors;
