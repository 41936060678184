import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  Card,
  IconButton,
  Box,
  Avatar,
  Tooltip,
  Modal,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import nodatafound from "../assets/background/nodatafound.png";
import {
  view_members_advance_searchAPI,
  View_members_by_family_codeAPI,
} from "../Features/services";
import { Strings } from "../Common/String";
import { Imageurl } from "../Features/apisUrl";
import PersonIcon from "@mui/icons-material/Person";

function SearchMemberView() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [count, setCount] = useState(0);
  const [open, setOpen] = useState(false);

  const [isEdit] = useState(
    location && location.state && location.state.category ? true : false
  );

  const [village_id, setVillageId] = useState(
    isEdit ? location.state.data.village_id : 0
  );
  const [marital_status, setMaritalStatus] = useState(
    isEdit ? location.state.data.marital_status : 0
  );
  const [blood_group, setBloodGroup] = useState(
    isEdit ? location.state.data.blood_group : ""
  );
  const [education_sub_id, setEducationSubId] = useState(
    isEdit ? location.state.data.education_sub_id : 0
  );
  const [sakh_id, setSakhId] = useState(
    isEdit ? location.state.data.sakh_id : 0
  );
  const [name, setName] = useState(isEdit ? location.state.data.name : "");
  const [contact, setContact] = useState(
    isEdit ? location.state.data.contact : ""
  );
  const [residence_address, setResidenceAddress] = useState(
    isEdit ? location.state.data.residence_address : ""
  );
  const [gender, setGender] = useState(
    isEdit ? location.state.data.gender : ""
  );
  const [from_age, setFromAge] = useState(
    isEdit ? location.state.data.from_age : ""
  );
  const [to_age, setToAge] = useState(isEdit ? location.state.data.to_age : "");
  const [matrimonial_status, setMatrimonialStatus] = useState(
    isEdit ? location.state.data.matrimonial_status : ""
  );
  const [city, setCity] = useState(isEdit ? location.state.data.city : "");
  const [visa_type_name, setVisaTypeName] = useState(
    isEdit ? location.state.data.visa_type_name : ""
  );
  const [country_id, setCountryId] = useState(
    isEdit ? location.state.data.country_id : 0
  );
  const [occupation, setOccupation] = useState(
    isEdit ? location.state.data.occupation : 0
  );
  const [education_id, setEducationId] = useState(
    isEdit ? location.state.data.education_id : 0
  );

  const [selectedMember, setSelectedMember] = useState(null);
  const handleOpen = (index, datas) => {
    if (datas?.photo) {
      setSelectedMember(data[index]);
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const CategoryResponse = await view_members_advance_searchAPI({
          name,
          village_id,
          gender,
          from_age,
          to_age,
          marital_status,
          blood_group,
          education_id,
          education_sub_id,
          country_id,
          city,
          matrimonial_status,
          offset,
          contact,
          residence_address,
          sakh_id,
          occupation,
          visa_type_name,
          limit: 30,
        });
        if (CategoryResponse.data) {
          setData(CategoryResponse.data);
          setCount(CategoryResponse.count);
        } else {
          setData([]);
          setCount(0);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setData([]);
        setCount(0);
      }
    };

    fetchData();
  }, [offset]);

  const handleViewDetails = async (rowData) => {
    const response = await View_members_by_family_codeAPI({
      offset: 0,
      family_code: rowData?.family_code,
    });
    navigate("/memberdetail", { state: { data: rowData, response } });
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handlePrevClick = () => {
    if (offset - 30 >= 0) {
      setOffset(offset - 30);
    }
  };

  const handleNextClick = () => {
    if (offset + 30 < count) {
      setOffset(offset + 30);
    }
  };

  const defaultProfileImage = <PersonIcon style={{ fontSize: 30 }} />;

  return (
    <>
      <div style={{ marginBottom: "5%" }}>
        <Card>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={3}
          >
            <Box
              style={{ marginTop: "2%", display: "flex", alignItems: "center" }}
            >
              <IconButton onClick={handleBack}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" gutterBottom>
                {Strings.MemberList}
              </Typography>
            </Box>
          </Box>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center">
                    <strong>{Strings.Image}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.FirstName}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.LastName}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.VillageName}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.Contact}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.Status}</strong>
                  </TableCell>
                  <TableCell align="center">
                    <strong>{Strings.Action}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      <Box display="flex" justifyContent="center">
                        <Tooltip title="View Photo">
                          <Avatar
                            src={Imageurl.member_image + row.photo}
                            alt={row.first_name}
                            sx={{
                              cursor: "pointer",
                              "&:hover, &:focus": {
                                zIndex: "10",
                              },
                            }}
                            onClick={() => handleOpen(index, row)}
                          >
                            {defaultProfileImage}
                          </Avatar>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    <TableCell align="center">{row.first_name}</TableCell>
                    <TableCell align="center">{row.surname}</TableCell>
                    <TableCell align="center">{row.village_name}</TableCell>
                    <TableCell align="center">
                      {row?.gender === "female" ? "" : row.contact}
                    </TableCell>
                    <TableCell align="center">
                      {row.death_status == 1 ? (
                        <Button
                          variant="contained"
                          color="error"
                          style={{
                            width: "20px",
                            height: "20px",
                            color: "white",
                            fontSize: "10px",
                            paddingTop: "8px",
                          }}
                        >
                          Dead
                        </Button>
                      ) : (
                        "ALIVE"
                      )}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton onClick={() => handleViewDetails(row)}>
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {data.length === 0 && (
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              p={3}
            >
              <img
                src={nodatafound}
                alt="No Data Found"
                style={{ width: "500px", height: "auto" }}
              />
            </Box>
          )}
        </Card>

        {data.length > 0 && (
          <Box
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
            mt={2}
          >
            {count > 30 && (
              <>
                {offset > 0 && (
                  <Button
                    onClick={handlePrevClick}
                    sx={{ mr: 1, backgroundColor: "#3b5998", color: "white" }}
                  >
                    {Strings.Prev}
                  </Button>
                )}
                {offset + 30 < count && (
                  <Button
                    onClick={handleNextClick}
                    sx={{ backgroundColor: "#3b5998", color: "white" }}
                  >
                    {Strings.Next}
                  </Button>
                )}
              </>
            )}
          </Box>
        )}

        {selectedMember && selectedMember.photo && (
          <Dialog open={open} onClose={handleClose}>
            <DialogContent>
              {selectedMember && selectedMember.photo ? (
                <img
                  src={Imageurl.member_image + selectedMember.photo}
                  alt="Member's Photo"
                  style={{ maxWidth: "100%", height: "auto" }}
                />
              ) : (
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <PersonIcon style={{ fontSize: 80, color: "gray" }} />
                </Box>
              )}
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </div>
    </>
  );
}

export default SearchMemberView;
