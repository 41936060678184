import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PostAPI, PostRequestAPI, PostRequestFileAPI } from './postApi';
import { APIurl } from './apisUrl';
import { API_KEY, LIVE } from './postApi';
import axios from 'axios';

// Define async thunks for each API call
export const viewSakh = createAsyncThunk('api/viewSakh', async () => {
  return await PostAPI(APIurl.view_sakh);
});

export const CurrentCountry_API = createAsyncThunk('api/currentCountry', async () => {
  return await PostAPI(APIurl.view_country);
});

export const ViewVisa_API = createAsyncThunk('api/viewVisa', async () => {
  return await PostAPI(APIurl.view_visa_type);
});

export const view_village_allAPI = createAsyncThunk('api/viewVillageAll', async (data) => {
  const formData = new URLSearchParams();
  formData.append('offset', data.offset);
  return await PostRequestAPI(APIurl.view_village_all, formData);
});

export const view_donerlist = createAsyncThunk('api/viewDonerlist', async (data) => {
  const formData = new URLSearchParams();
  formData.append('donor_type_id', data.donor_type_id);
  return await PostRequestAPI(APIurl.view_donors_list, formData);
});

export const Marital_API = createAsyncThunk('api/maritalStatus', async () => {
  return await PostAPI(APIurl.view_marital_status);
});

export const EducationType_API = createAsyncThunk('api/educationType', async () => {
  return await PostAPI(APIurl.view_education_type);
});

export const educationSubType = createAsyncThunk('api/educationSubType', async (data) => {
  const formData = new URLSearchParams();
  formData.append('education_id', data.education_id);
  return await PostRequestAPI(APIurl.view_education_sub_type, formData);
});

export const Occupation_API = createAsyncThunk('api/occupation', async () => {
  return await PostAPI(APIurl.view_occupation);
});

export const Bloodgroup_API = createAsyncThunk('api/bloodGroup', async () => {
  return await PostAPI(APIurl.view_blood_group);
});

export const Pragati_API = createAsyncThunk('api/pragatiMandal', async () => {
  return await PostAPI(APIurl.view_pragatimandal);
});

export const Relation_API = createAsyncThunk('api/relation', async () => {
  return await PostAPI(APIurl.view_relation);
});

export const addMember = createAsyncThunk('api/addMember', async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return await PostRequestFileAPI(APIurl.add_member_by_head_v3, formData);
});

export const updateMember = createAsyncThunk('api/updateMember', async (data) => {
  const formData = new FormData();
  Object.keys(data).forEach(key => formData.append(key, data[key]));
  return await PostRequestFileAPI(APIurl.update_member_v3, formData);
});



// Create the slice
const apiSlice = createSlice({
  name: 'api',
  initialState: {
    sakh: [],
    country: [],
    visa: [],
    villageAll: [],
    maritalStatus: [],
    educationType: [],
    educationSubType: [],
    occupation: [],
    bloodGroup: [],
    pragatiMandal: [],
    relation: [],
    donerlist: [],
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(viewSakh.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(viewSakh.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.sakh = action.payload;
      })
      .addCase(viewSakh.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(CurrentCountry_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(CurrentCountry_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.country = action.payload;
      })
      .addCase(CurrentCountry_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      // Add cases for other thunks similarly
      .addCase(ViewVisa_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(ViewVisa_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.visa = action.payload;
      })
      .addCase(ViewVisa_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(view_village_allAPI.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(view_village_allAPI.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.villageAll = action.payload;
      })
      .addCase(view_village_allAPI.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(Marital_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(Marital_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.maritalStatus = action.payload;
      })
      .addCase(Marital_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(EducationType_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(EducationType_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.educationType = action.payload;
      })
      .addCase(EducationType_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(educationSubType.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(educationSubType.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.educationSubType = action.payload;
      })
      .addCase(educationSubType.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(Occupation_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(Occupation_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.occupation = action.payload;
      })
      .addCase(Occupation_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(Bloodgroup_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(Bloodgroup_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.bloodGroup = action.payload;
      })
      .addCase(Bloodgroup_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(Pragati_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(Pragati_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.pragatiMandal = action.payload;
      })
      .addCase(Pragati_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(Relation_API.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(Relation_API.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.relation = action.payload;
      })
      .addCase(Relation_API.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(addMember.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(addMember.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(addMember.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      }).addCase(view_donerlist.pending, (state) => {
        state.donerlist = 'loading';
      })
      .addCase(view_donerlist.fulfilled, (state, action) => {
        state.donerlist = 'succeeded';
      })
      .addCase(view_donerlist.rejected, (state, action) => {
        state.donerlist = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateMember.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateMember.fulfilled, (state, action) => {
        state.status = 'succeeded';
      })
      .addCase(updateMember.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export default apiSlice.reducer;
