import * as React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Imageurl } from '../Features/apisUrl';
import { useNavigate } from 'react-router-dom';
import { Strings } from '../Common/String';


export default function RecipeReviewCard({ besnu }) {
  const navigate = useNavigate();
    const handleChange = (e, temp) => {
        // const url = `/besnudata/${temp.besanu_id}`;
        // const state = encodeURIComponent(JSON.stringify({ content: temp }));
        // window.open(`${url}?state=${state}`, '_blank');
        navigate(`/besnudata`, {
          state: { temp: temp },
        });
    }

  return (
    <Card sx={{ maxWidth: 385, maxHeight: 379, marginBottom: '20px' }}>
      <CardMedia
        component="img"
        height="400"
        image={Imageurl.besanu_image + besnu.besanu_image}
        alt={Strings.ImgNotFound}
        onClick={(e) => handleChange(e, besnu)}
      />
    </Card>
  );
}
