import React, { useState, useEffect } from "react";
import { Grid, Typography, Card, CardContent, Box } from "@mui/material";
import { App_detailAPI } from "../Features/services";
import parse from "html-react-parser";

function Contactus() {
  const [data, setData] = useState("");
  const [title, setTitle] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const privacyResponse = await App_detailAPI({
          type: "4",
        });
        if (privacyResponse.ok === true && privacyResponse.data.length > 0) {
          setData(parse(privacyResponse.data[0].content));
          setTitle(privacyResponse.data[0].title);
        } else {
          console.error("Privacy data not found");
        }
      } catch (error) {
        console.error("Error fetching privacy data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Box mt={5} mb={5} display="flex">
      <Grid item xs={12} sm={10} md={8} lg={6} sx={{ width: '-webkit-fill-available' }}> 
        <Card sx={{ boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", borderRadius: "16px", width: "100%", backgroundColor: '#FAF9F6' }}>
          <CardContent>
            <Typography variant="h5" component="h1" gutterBottom>
              {title}
            </Typography>
            <Typography variant="body1">
              {data}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Box>
  );
}

export default Contactus;
