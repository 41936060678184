import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { DonorAPI } from "../Features/services";
import { Imageurl } from "../Features/apisUrl";
import { Card, IconButton, TablePagination } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Strings } from "../Common/String";
import nodatafound from '../assets/background/nodatafound.png';
import '../styles/AppDonorsList.scss'; // Import the external CSS file

function AppDonorsList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setdata] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    (async () => {
      try {
        const CategoryResponse = await DonorAPI({
          donor_type_id: location.state.donor_type_id,
        });

        if (CategoryResponse.ok === true) {
          setdata(CategoryResponse.data || []);
        } else {
          setdata([]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    })();
  }, [location.state.donor_type_id]);

  const handleOpen = (image) => {
    setOpen(true);
    setSelectedImage(image);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleBack = () => {
    navigate(-1);
  };

  return (
    <Card style={{ marginBottom: '30px' }}>
      <Box className="appdonors-container">
        <Box className="appdonors-header">
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" gutterBottom className="appdonors-header-title">
            {Strings.ListOfMembers}
          </Typography>
        </Box>
      </Box>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{Strings.DonorImage}</TableCell>
              <TableCell>{Strings.DonorType}</TableCell>
              <TableCell>{Strings.VillageName}</TableCell>
              <TableCell>{Strings.DonorName}</TableCell>
              <TableCell>{Strings.Contact}</TableCell>
              <TableCell>{Strings.Remarks}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((donor) => (
                <TableRow key={donor.donor_id} onClick={() => handleOpen(donor.donor_image)}>
                  <TableCell>
                    <img
                      src={Imageurl.donor_image + donor.donor_image}
                      alt={donor.donor_name}
                      className="donor-image"
                    />
                  </TableCell>
                  <TableCell>{donor.donor_type_name}</TableCell>
                  <TableCell>{donor.village_name}</TableCell>
                  <TableCell>{donor.donor_name}</TableCell>
                  <TableCell>{donor.gender == "female" ? "" : donor.contact}</TableCell>
                  <TableCell>{donor.remarks}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <Box className="no-data-container">
                  <img src={nodatafound} alt="No Data Found" style={{ width: '500px', height: 'auto' }} />
                    {/* <NoDataFound style={{ fontSize: 80, color: "gray" }} />
                    <Typography variant="h6" color="gray">
                      {Strings.NoDataFound}
                    </Typography> */}
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box className="modal-content">
          <Button className="modal-close-button" onClick={handleClose}>
            X
          </Button>
          {selectedImage && (
            <img
              src={Imageurl.donor_image + selectedImage}
              alt="profile-image-modal"
              className="modal-image"
            />
          )}
        </Box>
      </Modal>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Card>
  );
}

export default AppDonorsList;
