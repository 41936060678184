import React from "react";
import "../styles/Header.scss";
import logo from "../assets/logo/main_logo.png";
import { Link } from "react-router-dom";
import { Strings } from "../Common/String";

const Header = () => {
  return (
    <header className="header">
      <div className="header-left">
      <Link to="/">
        <img src={logo} alt="Logo" className="header-logo" />
        </Link>
        {Strings.mainTitle}
    
      </div>
      <div className="header-right">
        <Link to="/signin" className="header-link">
          <button className="header-button">{Strings.SignIn}</button>
        </Link>
        <Link to="/signup" className="header-link">
          <button className="header-button">{Strings.SignUp}</button>
        </Link>
      </div>
    </header>
  );
};

export default Header;
