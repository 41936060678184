import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import {
  Grid,
  InputLabel,
  Box,
  Typography,
  TextField,
  Button,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { view_education_sub_type_API } from "../Features/services";
import { useSelector } from "react-redux";
import { Strings } from "../Common/String";

function Search() {
  const history = useNavigate();

  const [gender, setGender] = useState("");

  const bloodgroupData = useSelector((state) => state.country.bloodgroupData);
  const educationData = useSelector((state) => state.country.educationData);
  const materialData = useSelector((state) => state.country.materialData);
  const occupationData = useSelector((state) => state.country.occupationData);
  const pragatiData = useSelector((state) => state.country.pragatiData);
  const visaData = useSelector((state) => state.country.visaData);
  const viewVillage = useSelector((state) => state.country.viewVillage);
  const countryData = useSelector((state) => state.country.countryData);
  const sakhData = useSelector((state) => state.country.sakhdata);

  const [sakhdata, setsakhdata] = useState([]);
  const [sakh_id, setsakh_id] = useState("");
  const [countrydata, setcountrydata] = useState([]);
  const [country_id, setcountry_id] = useState("");
  const [villagedata, setvillagedata] = useState([]);
  const [village_id, setvillage_id] = useState("");
  const [offset, setoffset] = useState(0);
  const [maritaldata, setmaritaldata] = useState([]);
  const [marital_status, setmarital_status] = useState("");
  const [showbox, setshowbox] = useState(true);
  const [educationTypedata, seteducationTypedata] = useState([]);
  const [education_id, seteducation_id] = useState("");
  const [educationsubTypedata, seteducationsubTypedata] = useState([]);
  const [education_sub_id, seteducation_sub_id] = useState("");
  const [occupationdata, setoccupationdata] = useState([]);
  const [occupation, setoccupation] = useState("");
  const [mosal, setmosal] = useState("");
  const [blooddata, setblooddata] = useState([]);
  const [blood_group, setblood_group] = useState("");
  const [pragatimandal_id, setpragatimandal_id] = useState("");
  const [height, setheight] = useState();
  const [weight, setweight] = useState();
  const [heightinch, setheightinch] = useState();
  const [surname, setsurname] = useState();
  const [first_name, setfirst_name] = useState();
  const [last_name, setlast_name] = useState();
  const [middle_name, setmiddle_name] = useState("");
  const [contact, setcontact] = useState("");
  const [contact_2, setcontact_2] = useState();
  const [email, setemail] = useState();
  const [designation, setdesignation] = useState();
  const [company_name, setcompany_name] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [age, setage] = useState();
  const [residence_address, setresidence_address] = useState("");
  const [occupation_address, setoccupation_address] = useState();
  const [about_me, setabout_me] = useState();
  const [matrimonial_status, setmatrimonial_status] = useState("");
  const [country_code, setcountry_code] = useState("");
  const [name, setname] = useState("");
  const [visa_type_name, setvisa_type_name] = useState("");
  const [from_age, setfrom_age] = useState("");
  const [to_age, setto_age] = useState("");
  const [city, setcity] = useState("");
  const [visadata, setvisadata] = useState([]);
  const reduxData = useSelector((state) => state.country);
  const [showvisa, setshowvisa] = useState(false);

  useEffect(() => {
    if (reduxData && reduxData.visaData) {
      const sortedCountries = reduxData.visaData
        .slice()
        .sort((a, b) => a.visa_type_name.localeCompare(b.visa_type_name));
      const mappedCountries = sortedCountries.map((item) => ({
        label: item.visa_type_name,
        value: item.visa_type_name,
      }));
      setvisadata(mappedCountries);
    }
  }, [visaData]);


  function viewclick() {
    history(`/viewallmember`, {
      state: {
        data: {
          name: name,
          village_id: village_id,
          gender: gender,
          from_age: from_age,
          to_age: to_age,
          marital_status: marital_status,
          blood_group: blood_group,
          education_id: education_id,
          education_sub_id: education_sub_id,
          country_id: country_id,
          city: city,
          matrimonial_status: matrimonial_status,
          offset: offset,
          contact: contact,
          residence_address: residence_address,
          sakh_id: sakh_id,
          occupation: occupation,
          visa_type_name: visa_type_name,
        },
        category: true,
      },
    });
  }

  const calculateAge = (date) => {
    if (date) {
      const currentDate = new Date();
      const birthDate = new Date(date);
      const ageInMillis = currentDate - birthDate;
      const calculatedAge = Math.floor(
        ageInMillis / (1000 * 60 * 60 * 24 * 365)
      );
      setage(calculatedAge);
    } else {
      setage("");
    }
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push({ value: i, label: i.toString() });
    }
    return options;
  };

  const options = generateOptions(1, 100);

  useEffect(() => {
    (async () => {
      const bloodResponse = await countryData;
      if (Array.isArray(bloodResponse) && bloodResponse?.length > 0) {
        const copyOfData = [...bloodResponse];
        setcountrydata(
          copyOfData
            .sort((a, b) => a?.country_name?.localeCompare(b?.country_name))
            .map((item) => {
              return {
                label: item?.country_name,
                value: item?.country_id,
              };
            })
        );
      }
    })();
  }, []);

  function handleChangeCountry(event) {
    setcountry_code(event.target.value);
    setcountry_id(event.target.value);
    if (event.target.value == 1) {
      setshowvisa(false);
    } else {
      setshowvisa(true);
    }
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await viewVillage;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        const copyOfData = [...villageResponse];
        setvillagedata(
          copyOfData
            .sort((a, b) => a?.village_name?.localeCompare(b?.village_name))
            .map((item) => {
              return {
                label: item?.village_name,
                value: item?.village_id,
              };
            })
        );
      }
    })();
  }, []);

  function handleChangeVillage(event) {
    setvillage_id(event.target.value);
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await materialData;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        const copyOfData = [...villageResponse];
        setmaritaldata(
          copyOfData
            .sort((a, b) => a?.marital_name?.localeCompare(b?.marital_name))
            .map((item) => {
              return {
                label: item?.marital_name,
                value: item?.marital_id,
              };
            })
        );
      }
    })();
  }, []);

  function handleChangeMarital(event) {
    setmarital_status(event.target.value);
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await educationData;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        const copyOfData = [...villageResponse];
        seteducationTypedata(
          copyOfData
            .sort((a, b) => a?.education_name?.localeCompare(b?.education_name))
            .map((item) => {
              return {
                label: item?.education_name,
                value: item?.education_id,
              };
            })
        );
      }
    })();
  }, []);

  function handleChangeEducationType(event) {
    seteducation_id(event.target.value);
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await view_education_sub_type_API({
        education_id: education_id,
      });
      if (Array.isArray(villageResponse) && villageResponse?.data?.length > 0) {
        const copyOfData = [...villageResponse];
        seteducationsubTypedata(
          copyOfData?.data
            .sort((a, b) =>
              a?.education_sub_name?.localeCompare(b?.education_sub_name)
            )
            .map((item) => {
              return {
                label: item?.education_sub_name,
                value: item?.education_sub_id,
              };
            })
        );
      }
    })();
  }, [education_id]);

  function handleChangeEducationSubType(event) {
    seteducation_sub_id(event.target.value);
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await occupationData;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        const copyOfData = [...villageResponse];
        setoccupationdata(
          copyOfData
            .sort((a, b) => a?.occupation_name?.localeCompare(b?.occupation_name))
            .map((item) => {
              return {
                label: item?.occupation_name,
                value: item?.occupation_id,
              };
            })
        );
      }
    })();
  }, []);

  function handleChangeOccupation(event) {
    setoccupation(event.target.value);
  }

  useEffect(() => {
    (async () => {
      const villageResponse = await sakhData;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        const copyOfData = [...villageResponse];
        setsakhdata(
          copyOfData
            .sort((a, b) => a?.sakh_name?.localeCompare(b?.sakh_name))
            .map((item) => {
              return {
                label: item?.sakh_name,
                value: item?.sakh_id,
              };
            })
        );
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const villageResponse = await bloodgroupData;
      if (Array.isArray(villageResponse) && villageResponse?.length > 0) {
        console.log('villageResponse ', villageResponse)
        const copyOfData = [...villageResponse];
        const sortedData = copyOfData?.sort((a, b) =>
          a?.group_name?.localeCompare(b?.group_name)
        );
        const mappedData = sortedData.map((item) => ({
          label: item?.group_name,
          value: item?.group_id,
        }));
        setblooddata(mappedData);
      }
    })();
  }, []);

  function handleChangeBlood(event) {
    setblood_group(event.target.value);
  }
  function handleChangecontact(event) {
    const value = event.target.value;
    const numericValue = value.replace(/\D/g, "");
    setcontact(numericValue);
  }
  function handleChangeName(event) {
    setname(event.target.value);
  }
  function handleChangefrom_age(event) {
    setfrom_age(event.target.value);
  }
  function handleChangeto_age(event) {
    setto_age(event.target.value);
  }
  function handleChangecity(event) {
    setresidence_address(event.target.value);
  }


  return (
    <Box py={3} height={200}>
      <Grid container spacing={1}>
        <Grid item xs={12} lg={12}>
          <Card sx={{ overflow: "visible", marginBottom: "70px !important" }}>
            <Box pt={2} px={2} height={100} mt={2} mx={1}>
              <Box
                color="white"
                // mb={0.5}
                // lineHeight={1}
                height={100}
                mt={1}
              >
                <Typography color="black" variant="h5" fontWeight="medium">
                  {Strings.MemberSearch}
                </Typography>
              </Box>
            </Box>
            <Box pb={3} px={3}>
              <Box component="form" role="form">
                <Box mb={2}>
                  <Box mb={2}>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.name}</InputLabel>
                              <TextField
                                value={name}
                                onChange={handleChangeName}
                                placeholder={Strings.name}
                                fullWidth
                                required
                                InputProps={{
                                  style: {
                                    color: name ? '#888' : 'initial', // Grey color if there is input, otherwise initial (default) color
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.village}</InputLabel>
                              <Select
                                label={Strings.village}
                                fullWidth
                                value={village_id}
                                onChange={handleChangeVillage}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectNative}
                                </MenuItem>
                                {villagedata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.gender}</InputLabel>
                              <Select
                                label={Strings.gender}
                                fullWidth
                                value={gender}
                                onChange={(e) => setGender(e.target.value)}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectGender}
                                </MenuItem>
                                <MenuItem value="male">{Strings.Male}</MenuItem>
                                <MenuItem value="female">
                                  {Strings.Female}
                                </MenuItem>
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.age_from}</InputLabel>
                              <Select
                                label={Strings.age_from}
                                fullWidth
                                value={from_age}
                                onChange={handleChangefrom_age}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.FromAge}
                                </MenuItem>
                                {options.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.age_to}</InputLabel>
                              <Select
                                label={Strings.age_to}
                                fullWidth
                                value={to_age}
                                onChange={handleChangeto_age}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.ToAge}
                                </MenuItem>
                                {options.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.blood_group}</InputLabel>
                              <Select
                                label={Strings.blood_group}
                                fullWidth
                                value={blood_group}
                                onChange={handleChangeBlood}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectBloodGroup}
                                </MenuItem>
                                {blooddata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.education_type}</InputLabel>
                              <Select
                                label={Strings.education_type}
                                fullWidth
                                value={education_id}
                                onChange={handleChangeEducationType}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectEducation}
                                </MenuItem>
                                {educationTypedata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>
                                {Strings.education_sub_type}
                              </InputLabel>
                              <Select
                                label={Strings.education_sub_type}
                                fullWidth
                                value={education_sub_id}
                                onChange={handleChangeEducationSubType}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectEducationSubType}
                                </MenuItem>
                                {educationsubTypedata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.occupation}</InputLabel>
                              <Select
                                label={Strings.occupation}
                                fullWidth
                                value={occupation}
                                onChange={handleChangeOccupation}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectOccupation}
                                </MenuItem>
                                {occupationdata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        {/* <Grid item xs={12} sm={6}>
                            <Box mb={2}>
                              <Box mb={2}>
                                <InputLabel>Pragati Mandal</InputLabel>
                                <Select
                                  label="Pragati Mandal"
                                  fullWidth
                                  value={pragatimandal_id}
                                  onChange={handleChangePragati}
                                  displayEmpty
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  <MenuItem disabled value="">
                                    {Strings.SelectPragatiMandal}
                                  </MenuItem>
                                  {pragatidata.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </Box>
                            </Box>
                          </Grid> */}
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.phone_required}</InputLabel>
                              <TextField
                                value={contact}
                                onChange={handleChangecontact}
                                placeholder={Strings.phone_required}
                                fullWidth
                                required
                                InputProps={{
                                  inputProps: {
                                    pattern: "[0-9]*",
                                  },
                                  style: {
                                    color: contact ? '#888' : 'initial', // Grey color if there is input, otherwise initial (default) color
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.marital_status}</InputLabel>
                              <Select
                                label={Strings.marital_status}
                                fullWidth
                                value={marital_status}
                                onChange={handleChangeMarital}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectMaritalStatus}
                                </MenuItem>
                                {maritaldata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>
                                {Strings.residence_address}
                              </InputLabel>
                              <TextField
                                value={residence_address}
                                onChange={handleChangecity}
                                placeholder={Strings.residence_address}
                                fullWidth
                                required
                                InputProps={{
                                  style: {
                                    color: residence_address ? '#888' : 'initial', // Grey color if there is input, otherwise initial (default) color
                                  },
                                }}
                              />
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box mb={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              <InputLabel>{Strings.current_country}</InputLabel>
                              <Select
                                label="Country"
                                fullWidth
                                value={country_id}
                                onChange={handleChangeCountry}
                                displayEmpty
                                style={{
                                  color: '#AEAFAF', // Adjust color as needed
                                  '& .MuiInput-underline:before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                  },
                                  '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                    borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                  },
                                }}
                                inputProps={{ "aria-label": "Without label" }}
                              >
                                <MenuItem disabled value="">
                                  {Strings.SelectCountry}
                                </MenuItem>
                                {countrydata.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Box mb={2}>
                            <Box mb={2}>
                              {showvisa && (
                                <Grid item xs={15}>
                                  <InputLabel
                                    sx={{
                                      fontSize: "small",
                                      fontWeight: "bold",
                                      // marginBottom: "6px",
                                      marginTop: "14px",
                                    }}
                                  >
                                    {Strings.visa_type}
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    placeholder={Strings.select_visa_type}
                                    value={visa_type_name}
                                    onChange={(e) => {
                                      setvisa_type_name(e.target.value);
                                    }}
                                    displayEmpty
                                    style={{
                                      color: '#AEAFAF', // Adjust color as needed
                                      '& .MuiInput-underline:before': {
                                        borderBottomColor: '#AEAFAF', // Adjust underline color as needed
                                      },
                                      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
                                        borderBottomColor: '#AEAFAF', // Adjust underline color on hover as needed
                                      },
                                    }}
                                    inputProps={{ "aria-label": "Without label" }}
                                  >
                                    <MenuItem value={0} disabled>
                                      {Strings.select_visa_type}
                                    </MenuItem>
                                    {visadata.map((option) => (
                                      <MenuItem
                                        key={option.value}
                                        value={option.value}
                                      >
                                        {option.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </Grid>
                              )}
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              mb={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Button
                style={{
                  marginBottom: "15px",
                  backgroundColor: "#4682B4",
                  color: "white",
                }}
                onClick={viewclick}
              >
                {Strings.Search}
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Search;
