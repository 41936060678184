import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions } from '@mui/material';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import truncate from 'truncate-html';
import { Imageurl } from '../Features/apisUrl';
import '../styles/NewsCard.scss'; // Importing the CSS file for custom styles
import { Strings } from '../Common/String';

const NewsCard = ({ news }) => {
  const navigate = useNavigate();

  const handleLearnMore = (e, data) => {
    navigate(`/news`, {
      state: { data: data },
    });
  };

  const truncatedDesc = truncate(news.news_desc, { length: 50, stripTags: true });
  const truncatedtitle = truncate(news.news_title, { length: 50, stripTags: true });

  return (
    <Card className="news-card">
      <CardActionArea onClick={(e) => handleLearnMore(e, news)} className="card-action-area">
        <CardMedia
          component="img"
          image={Imageurl.news_image + news.news_image}
          alt={news.news_title}
          className="card-media"
        />
        <CardContent className="card-content">
          <Typography gutterBottom variant="h6" component="div">
            {parse(truncatedtitle)}
          </Typography>
          <Typography variant="body2" color="text.secondary" className="card-description">
            {parse(truncatedDesc)}...
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions className="card-actions">
        <Button size="small" color="primary" onClick={(e) => handleLearnMore(e, news)}>
          {Strings.LearnMore}
        </Button>
      </CardActions>
    </Card>
  );
};

export default NewsCard;
