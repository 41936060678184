import React, { useEffect, useState } from "react";
import { Grid, Card, CardContent, Typography, IconButton, CardMedia } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Skeleton } from "@mui/material";
import { BesanuAPI, DonersAPI } from "../Features/services";
import { Strings } from "../Common/String";
import diamondDoner from '../../src/assets/logo/diamonddonor.png'; // Import your image
import golddonor from '../../src/assets/logo/golddonor.png';
import paltinuimdonor from '../../src/assets/logo/paltinuimdonor.png';
import silverdonor from '../../src/assets/logo/silverdonor.png';

function Donations() {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const CategoryResponce = await BesanuAPI({ offset: 0 });
  //       if (CategoryResponce?.ok === true) {
  //         setData(CategoryResponce.data || []);
  //       } else {
  //         setData([]);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //       setData([]);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, []);

  const handleCardClick = async(data) => {
    try {
      const CategoryResponce = await DonersAPI({ donor_type_id: data });
      console.log('CategoryResponce.data 1', CategoryResponce);
      // if (CategoryResponce) {
        setData(CategoryResponce || []);
        console.log('CategoryResponce.data ', CategoryResponce);
        navigate("/donerlist", { state: CategoryResponce });
      // } else {
      //   setData([]);
      // }
    } catch (error) {
      console.error("Error fetching data:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <Skeleton variant="rectangular" width="100%" height={400} animation="wave" />
    );
  }

  return (
    <>
      <Typography gutterBottom mt={3} style={{ color: '#777', fontSize: '20px', fontWeight: 500 }}>
        {Strings.Donations}
      </Typography>
      <br />
      <Grid container spacing={3}>
        {/* Diamond Donor Card */}
        <Grid item xs={12} md={6} xl={3} onClick={() => handleCardClick("1")}>
        <Card className="mui-card" variant="outlined" sx={{ height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              image={diamondDoner}
              alt="Diamond Donor"
              sx={{ width: '150px', height: '150px', objectFit: 'cover', mb: 1, mt: 2 }}
            />
            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" component="div" align="center" style={{ fontWeight: 700 }}>
                Diamond Donor
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Golden Donor Card */}
        <Grid item xs={12} md={6} xl={3} onClick={() => handleCardClick("3")}>
        <Card className="mui-card" variant="outlined" sx={{ height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              height="150"
              image={golddonor}
              alt="Golden Donor"
              sx={{ width: '150px', height: '150px', objectFit: 'cover', mb: 1, mt: 2 }}
            />
       <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" component="div" align="center" style={{ fontWeight: 700 }}>
                Golden Donor
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Platinum Donor Card */}
        <Grid item xs={12} md={6} xl={3} onClick={() => handleCardClick("2")}>
        <Card className="mui-card" variant="outlined" sx={{ height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              height="150"
              image={paltinuimdonor}
              alt="Platinum Donor"
              sx={{ width: '150px', height: '150px', objectFit: 'cover', mb: 1, mt: 2 }}
            />
           <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" component="div" align="center" style={{ fontWeight: 700 }}>
                Platinum Donor
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        {/* Silver Donor Card */}
        <Grid item xs={12} md={6} xl={3} onClick={() => handleCardClick("4")}>
        <Card className="mui-card" variant="outlined" sx={{ height: "100%", display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              height="150"
              image={silverdonor}
              alt="Silver Donor"
              sx={{ width: '150px', height: '150px', objectFit: 'cover', mb: 1, mt: 2 }}
            />
             <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" component="div" align="center" style={{ fontWeight: 700 }}>
                Silver Donor
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Donations;
